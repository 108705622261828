import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { HeadingBuilder } from './HeadingBuilder';
import * as Constants from '../constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";    

export const StripeCircleBuyButton = ({ whichPlan }) => {
  const {
    signedIn, shopper, clientReferenceId,
    stripeIsLive
  } = useContext(ShopperContext);
  console.log('scbb whichPlan', whichPlan);
  console.log('scbb clientReferenceId', clientReferenceId);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pub_key_test    = "pk_test_51NSO67LAmhXuStLF8YbQvEgwut5TNhiPHvEQPEVeVns9uFC2ZJHA5VEQJqF6WfOYYll08ll9Sfaq1Fn4ki97xwf500QIVuv6Kh";
  const publishable_key = "pk_live_51NSO67LAmhXuStLF7Z9nLgFQkwBIY4QKDAWDpEC99k7QGgsRFUjOcfLL6diZLFjpfnjbFwd5pftF9COfaJQZk3Z100sDKUVSmP"

  // function lookup_plan ({
  //   plan,
  // }) {
  function lookup_plan (
    plan,
  ) {
    console.log('scbb plan', plan);
    switch (plan) {
      case  "weekly3us200": return {
          // "product_name_test": "Test - Up to 3 shoppers",
          "buy_button_id_test": "buy_btn_1OizSeLAmhXuStLFH6lCMNs5",
          // "product_name": "Up to 3 shoppers",
          "buy_button_id": "buy_btn_1OjRzDLAmhXuStLF4Iru69r0",
      };
      case  "monthly3us300": return {
          // "product_name_test": "Test - Up to 3 shoppers",
          "buy_button_id_test": "buy_btn_1OiIisLAmhXuStLFRJWtFMEA",
          // "product_name": "Up to 3 shoppers",
          "buy_button_id": "buy_btn_1OfcE3LAmhXuStLFer1T4JZ3",
      };
      case  "biannual3us1300": return {
          // "product_name_test": "Test - Up to 3 shoppers",
          "buy_button_id_test": "buy_btn_1OiJFVLAmhXuStLFeEwxPF6H",
          // "product_name": "Up to 3 shoppers",
          "buy_button_id": "buy_btn_1OiI1hLAmhXuStLFxAlJRgdY",
      };
      case  "monthly5us500": return {
          // "product_name_test": "Test - Up to 5 shoppers",
          "buy_button_id_test": "buy_btn_1OiJFVLAmhXuStLFeEwxPF6H",
          // "product_name": "Up to 5 shoppers",
          "buy_button_id": "buy_btn_1Ofc6rLAmhXuStLFBiBUrcu1",
      };
      case  "biannual5us1900": return {
          // "product_name_test": "Test - Up to 5 shoppers",
          "buy_button_id_test": "buy_btn_1OiiFYLAmhXuStLFbSduYOKN",
          // "product_name": "Up to 5 shoppers",
          "buy_button_id": "buy_btn_1OiiWiLAmhXuStLF6vITC0As",
      };
      case  "monthly8us800": return {
          // "product_name_test": "Test - Up to 8 shoppers",
          "buy_button_id_test": "buy_btn_1OiTjpLAmhXuStLFkzvoekEI",
          // "product_name": "Up to 8 shoppers",
          "buy_button_id": "buy_btn_1OesGSLAmhXuStLFAh0H0ZUb",
      };
      case  "biannual8us2900": return {
          // "product_name_test": "Test - Up to 8 shoppers",
          "buy_button_id_test": "buy_btn_1OiTo9LAmhXuStLFUQthDLO5",
          // "product_name": "Up to 8 shoppers",
          "buy_button_id": "buy_btn_1Ofc2ULAmhXuStLFTSFsrCpS",
      };
      default:  return {
          "buy_button_id": "",
          "publishable_key": "",
      };
    }
  };
  const stripeParams = lookup_plan(whichPlan)
  console.log('scbb sParams', stripeParams);
  console.log('scbb sParams.buy_button_id', stripeParams.buy_button_id);
  console.log('scbb sParams.buy_button_id_test', stripeParams.buy_button_id_test);
  console.log('scbb cust_email', shopper.email);

  // To delete the new circle, get its id from 'clientReferenceId'.
  const circle_id = clientReferenceId.substring(0, clientReferenceId.indexOf('|'));

  return (
   <>
    <div>
      {
        <stripe-buy-button
          buy-button-id={
            stripeIsLive
              ? stripeParams.buy_button_id
              : stripeParams.buy_button_id_test
          }

          publishable-key={
            stripeIsLive
              ? publishable_key
              : pub_key_test
          }

          client-reference-id={clientReferenceId}
          customer-email={shopper.email}
        >
        </stripe-buy-button>
      }
    </div>
    <HeadingBuilder
      headingText    = {t('circle.removeCircle.buttonLabelStripe')}
      // Delete this new Circle
      linkUrl        = {`/circles/${circle_id}/delete?clear_client_ref_id=1`}
      goButtonText   = {Constants.GET_ICON_RT_ARROW}
      tippyAlertText = {t('circle.removeCircle.tippyContentStripe')}
      tippyText      = {t('circle.removeCircle.tippyContentStripe')}
      // would be nice to clear clientReferenceId and run
      // 'setJustGotClearToBeFalse()' here, but works to signal via query string
    />
   </>
  );
};

export default StripeCircleBuyButton;
