import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useUtils } from "../hooks/useUtils";
import { usePhrasing } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import HeadingBuilder from './HeadingBuilder';
import { BackButton } from './BackButton';
import { NumericFormat } from 'react-number-format';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';

const SkuForm = ({circle_id, skuType, resetSkuTypeFormFlags, chosenUuid}) => {
  const { signedIn, shopper, addSku, circleShoppers,
          skus, foundSkus, unsetFoundSkus } = useContext(ShopperContext);
  const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
  const { setJustGotClearToFalse } = useContext(ShopperContext);
  const { tableShow, tableData, altBorderTopColor, visibilityCollapse } = useStyles();

  const search_sku_attrs = foundSkus.find(fs =>  fs.uuuid == chosenUuid);
  console.log('SkuForm.js search_sku_attrs: ', search_sku_attrs)

  console.log('SkuForm.js skuType: ', skuType)
  const isDiscrete = skuType == "DiscreteItem" ? true : false;
  const isGranular = skuType == "GranularItem" ? true : false;
  const isWeighty  = skuType == "WeightyItem" ? true : false;

  console.log('SkuForm.js circle_id: ', circle_id)
  const { t } = useTranslation();
  const { is_shopper_a_circle_admin, stringGen } = useUtils();
  const { tippySkuIdentifierString,
          typicalWeightLabel,
          typicalWeightTippyText,
          priceLabel,
          priceTippyText 
      } = usePhrasing();

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: circle_id, shopper_id: shopper.id
  });

  const initIdentifier = () => {
    // Generate a 'newSkuIdentifier'.
    // If no current sku identifier matches 'newSkuIdentifier' then
    // return 'newSkuIdentifier'.
    // return '' if we don't get a 'newSkuIdentifier' within four tries.

    let i = 0;
    const newSkuIds = [''];

    while (i < 4) {
      const newSkuIdentifier = stringGen({
        len: 4,  // 34*34*25*9 == 260,100 possible combinations 
        charsetAlpha:  t('global.identifierCharsetAlpha'),
        charsetDigits: t('global.identifierCharsetDigits')
      });

      // if filter finds that no sku identifiers match (expected), it returns []
      if (skus.filter(sku => sku.identifier == newSkuIdentifier).length > 0) {
        console.log('sf nsi is a dupe:', newSkuIdentifier);
        i = i + 1;
      } else {
        newSkuIds.push(newSkuIdentifier);
        break;
      }
    }

    return newSkuIds.pop();
  }

  // TODO: present commented-out attributes for entry in some Profile update
  const initialState = {
    circle_id: circle_id,
    shopper_id: '',
    currency: '$',
    //price_date: Date.today,
    price_date: '',
    discount_amount: '0',
    discount_start_date: '',
    discount_end_date: '',
    store_label: '',
    identifier: initIdentifier(),
    brand: '',
    label: '',
    detail: '',
    category: '',
    unit_count: (isGranular || isWeighty) ? 1 : '',
    typical_weight: '',
    price: '',
    tax_type: 2,  // 2 => 'Food'
    shareable: 0,
    is_hidden: 0,
    quantity: isWeighty ? 1 : '',
    units: isWeighty ? 'lb' : 'oz',
    unit_price: '',
    type: skuType,
  };

  if (search_sku_attrs  &&  skuType == search_sku_attrs.type) {
    for (const property in search_sku_attrs) {
      // don't add these properties to 'initialState'
      if ( [
             'shared_circle_identifier',
             'shared_circle_postal_code',
             'uuuid'
           ].indexOf(property) >= 0
         ) {continue;}
      
      // properties whose values came from Price start with 'shared_'.
      // To load these values into initialState, strip 'shared_' from property.
      const initStateProperty =
        property.indexOf('shared_') === 0  ? property.substring(7) : property;

      initialState[initStateProperty] = search_sku_attrs[property];
    }
  }

  const { formData, handleChange, handleChangeWithWhitespace, reset, editFormValues } = useForm(initialState);

 if (signedIn) {

  const handleSubmit = (e) => {
    e.preventDefault();

    // clear out any error messages, success statuses
    dismissErrorsList();
    setErrorsDoExistToFalse();
    setJustGotClearToFalse();

    console.log('formData for addSku: ', formData);
    addSku(formData);

    // If there is an error we want to preserve formData and show the message.

    // Clear out foundSkus (and chosenUuid) to reset attrs for this type
    unsetFoundSkus();
  }

  return (
      <article>
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.store_label.label')}
          // Store
          labelHtmlFor = "store_label"
          tippyAlertText = {t('sku.store_label.tippyContent')}
          tippyText      = {t('sku.store_label.tippyContent')}
          tippyInfoText  = {t('sku.store_label.tippyContent2')}
        />
        {/* This item will be purchased at which store(s)? You might include... */}
      </td>
      <td>
        <input
          key="10"
          type="text"
          id="store_label"
          name="store_label"
          onChange={handleChange}
          value={formData.store_label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.identifier.label')}
          // ID
          labelHtmlFor = "identifier_skf"
          tippyAlertText = { tippySkuIdentifierString({isWeighty}) }
          tippyText      = { tippySkuIdentifierString({isWeighty}) }
          tippyInfoText  = {t('sku.identifier.tippyContent2')}
        />
        {/* Short identifier for the item such as a store code, barcode, or a term you make up. If use barcode, don't include the little digits at bottom corners. */}
      </td>
      <td>
        <input
          key="20"
          type="text"
          style={{ textTransform: "uppercase" }}
          id="identifier_skf"
          name="identifier"
          onChange={handleChangeWithWhitespace}
          value={formData.identifier}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.brand.label')}
          // Brand
          labelHtmlFor = "brand"
          tippyAlertText = {t('sku.brand.tippyContent')}
          tippyText      = {t('sku.brand.tippyContent')}
          // e.g. Green Giant, General Mills, Heinz.
        />
      </td>
      <td>
        <input
          key="30"
          type="text"
          id="brand"
          name="brand"
          onChange={handleChange}
          value={formData.brand}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.label.label')}
          // Name/Label
          labelHtmlFor = "label"
          tippyAlertText = {t('sku.label.tippyContent')}
          tippyText      = {t('sku.label.tippyContent')}
          // Short, quick name of the item.
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label"
          name="label"
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.detail.label')}
          // Details
          labelHtmlFor = "detail"
          tippyAlertText = {t('sku.detail.tippyContent')}
          tippyText      = {t('sku.detail.tippyContent')}
          // Any details you would like to add for the item.
        />
      </td>
      <td>
        <textarea
          key="50"
          id="detail"
          name="detail"
          onChange={handleChange}
          value={formData.detail}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.category.label')}
          // Category
          labelHtmlFor = "category"
          tippyAlertText = {t('sku.category.tippyContent')}
          tippyText      = {t('sku.category.tippyContent')}
          // What category is the item? (Helpful for sorting.)
        />
      </td>
      <td>
        <select
          key="55"
          type="text"
          id="category"
          name="category"
          onChange={handleChange}
          value={formData.category}
        >
          {/* <option value="">Choose one:/option> */}
          <option value="">{t('sku.category.optionValue')}</option>
          {/* <option selected value="0">Other</option> // wish this worked... */}
          {/* <option value="0">Other</option> */}
          <option value="0">{t('sku.category.optionValue0')}</option>
          {/* <option value="1">Fruits</option> */}
          <option value="1">{t('sku.category.optionValue1')}</option>
          {/* <option value="2">Vegetables</option> */}
          <option value="2">{t('sku.category.optionValue2')}</option>
          {/* "optionValue3": "Dairy", */}
          <option value="3">{t('sku.category.optionValue3')}</option>
          {/* "optionValue4": "Bread, Bakery, Dessert", */}
          <option value="4">{t('sku.category.optionValue4')}</option>
          {/* "optionValue5": "Baking", */}
          <option value="5">{t('sku.category.optionValue5')}</option>
          {/* "optionValue6": "Meat, Fish", */}
          <option value="6">{t('sku.category.optionValue6')}</option>
          {/* "optionValue7": "Meat Alternatives", */}
          <option value="7">{t('sku.category.optionValue7')}</option>
          {/* "optionValue8": "Deli", */}
          <option value="8">{t('sku.category.optionValue8')}</option>
          {/* "optionValue9": "Cans, Jars", */}
          <option value="9">{t('sku.category.optionValue9')}</option>
          {/* "optionValue10": "Pasta, Rice, Cereal", */}
          <option value="10">{t('sku.category.optionValue10')}</option>
          {/* "optionValue11": "Sauces, Condiments", */}
          <option value="11">{t('sku.category.optionValue11')}</option>
          {/* "optionValue12": "Herbs, Spices", */}
          <option value="12">{t('sku.category.optionValue12')}</option>
          {/* "optionValue13": "Frozen Foods", */}
          <option value="13">{t('sku.category.optionValue13')}</option>
          {/* "optionValue14": "Snacks", */}
          <option value="14">{t('sku.category.optionValue14')}</option>
          {/* "optionValue15": "Drinks", */}
          <option value="15">{t('sku.category.optionValue15')}</option>
          {/* "optionValue16": "Household, Cleaning", */}
          <option value="16">{t('sku.category.optionValue16')}</option>
          {/* "optionValue17": "Personal Care, Beauty", */}
          <option value="17">{t('sku.category.optionValue17')}</option>
          {/* "optionValue18": "Health, Vitamins", */}
          <option value="18">{t('sku.category.optionValue18')}</option>
          {/* "optionValue19": "Baby products", */}
          <option value="19">{t('sku.category.optionValue19')}</option>
          {/* "optionValue20": "Pet Care", */}
          <option value="20">{t('sku.category.optionValue20')}</option>
          {/* "optionValue21": "Office Supplies", */}
          <option value="21">{t('sku.category.optionValue21')}</option>
          {/* "optionValue22": "Clothing", */}
          <option value="22">{t('sku.category.optionValue22')}</option>
          {/* "optionValue23": "Automotive", */}
          <option value="23">{t('sku.category.optionValue23')}</option>
          {/* "optionValue24": "Electronics", */}
          <option value="24">{t('sku.category.optionValue24')}</option>
          {/* "optionValue25": "Patio, Lawn, Garden" */}
          <option value="25">{t('sku.category.optionValue25')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>
    <tr
      // If isDiscrete, then don't hide this row. Else hide row.
      style={isDiscrete ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.unit_count.label')}
          // Num Items in Sku
          labelHtmlFor = "unit_count"
          tippyAlertText = {t('sku.unit_count.tippyContent')}
          tippyText = {t('sku.unit_count.tippyContent')}
        />
        {/* "tippyContent": "The item contains individual units. How many are there?" */}
      </td>
      <td>
        <NumericFormat displayType="input"
          key="60"
          type="text"
          id="unit_count"
          name="unit_count"
          onChange={handleChange}
          value={formData.unit_count}
          decimalScale={0}
          hidden={isDiscrete ? false : true}
        />
        &nbsp;*
      </td>
    </tr>
    <tr
      style={isWeighty ? null : visibilityCollapse }
    >
      <td>
        {/* TODO: would be nice to put this in bold if we arrived via Link, i.e. by someone
        clicking on the "EST 12.5 lb" link in the ShoppingList. The bolding would make
        it easier to see the key field to edit. Signal by appending option to link? */}
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {typicalWeightLabel()}
          // Typical weight (Only a number, no units)
          labelHtmlFor = "typical_weight"
          tippyAlertText = {typicalWeightTippyText()}
          tippyText      = {typicalWeightTippyText()}
        />
        {/* If the item is sold by weight (e.g. Chicken Thighs are sold by the pound), how much does a typical package weigh? If a package of Chicken Thighs is typically 10 pounds, enter '10' here. */}
      </td>
      <td>
        <NumericFormat displayType="input"
          key="70"
          type="text"
          id="typical_weight"
          name="typical_weight"
          onChange={handleChange}
          value={formData.typical_weight}
          decimalScale={2}
          hidden={isWeighty ? false : true}
        />
        &nbsp;*
      </td>
    </tr>
    <tr
      style={isWeighty ? visibilityCollapse : null }
    >
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.quantity.label')}
          // Total Weight/Quantity
          labelHtmlFor = "quantity"
          tippyAlertText = {t('sku.quantity.tippyContent')}
          tippyText      = {t('sku.quantity.tippyContent')}
          tippyInfoText  = {t('sku.quantity.dontSayUnits')}
        />
        {/* What is the total weight/quantity of the product? For example, if the package contains six 10-oz cans of something, the value here would be 60. ** Enter '1' if the item is priced by weight (meat, fish, etc.) */}
      </td>
      <td>
        <NumericFormat displayType="input"
          key="80"
          type="text"
          id="quantity"
          name="quantity"
          onChange={handleChange}
          value={formData.quantity}
          decimalScale={2}
          hidden={isWeighty ? true : false}
        />
        {
          isGranular ? <>&nbsp;*</> : null
        }
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.units.label')}
          // Units, e.g. oz, g, lb, ft2
          labelHtmlFor = "units"
          tippyAlertText = {t('sku.units.tippyContent')}
          tippyText      = {t('sku.units.tippyContent')}
        />
        {/* What is the unit of measure for the item's quantity/total weight? Is it measured in ounces (oz), pounds (lb), square feet (ft2)? Maybe you are buying a pack of 40 AA batteries, so you would put 'AA battery'. */}
      </td>
      <td>
        <input
          key="90"
          type="text"
          id="units"
          name="units"
          onChange={handleChange}
          value={formData.units}
        />
        {
          isWeighty && <span>&nbsp;*</span>
        }
      </td>
    </tr>
    <tr
      // Hardcoding with 'false' hides row for now. Maybe Circle should set this; here could be override, like how trip's tax rates can override circle's tax settings.
      style={false ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.currency.label')}
          // Currency Symbol
          labelHtmlFor = "currency_skf"
          // tippyAlertText = {t('sku.currency.tippyContent')}
          // tippyText      = {t('sku.currency.tippyContent')}
        />
      </td>
      <td>
        <input
          key="100"
          type="text"
          id="currency_skf"
          name="currency"
          onChange={handleChange}
          value={formData.currency}
          hidden={true}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {priceLabel({isWeighty})}
          // Price (decimal) $[_______]
          labelHtmlFor = "price_skf"
          tippyAlertText = {priceTippyText({isWeighty})}
          tippyText      = {priceTippyText({isWeighty})}
        />
        {/* A number showing the price of the item. Don't include a currency symbol such as '$'. */}
      </td>
      <td>
        <NumericFormat displayType="input"
          key="110"
          type="text"
          id="price_skf"
          name="price"
          onChange={handleChange}
          value={formData.price}
          decimalScale={2}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.price_date.label')}
          // Price AsOf Date
          labelHtmlFor = "price_date_skf"
          tippyAlertText = {t('sku.price_date.tippyContent')}
          tippyText      = {t('sku.price_date.tippyContent')}
        />
        {/* When did/will the value in Price become the price? ShopWeAll will use the most recent 'Price AsOf Date' before a trip to price an item. */}
      </td>
      <td>
        <input
          key="115"
          type="date"
          id="price_date_skf"
          name="price_date"
          onChange={handleChange}
          value={formData.price_date}
        />
        &nbsp;*
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.discount_amount.label')}
          // Discount Amount
          labelHtmlFor = "discount_amount_skf"
          tippyAlertText = {t('sku.discount_amount.tippyContent')}
          tippyText      = {t('sku.discount_amount.tippyContent')}
        />
        {/* Value of any discount. This will be subtracted from the item's price to show the purchase price. */}
      </td>
      <td>
        <NumericFormat displayType="input"
          key="120"
          type="text"
          id="discount_amount_skf"
          name="discount_amount"
          onChange={handleChange}
          value={formData.discount_amount}
          decimalScale={2}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.discount_start_date.label')}
          // Discount Start Date
          labelHtmlFor = "discount_start_date_skf"
          tippyAlertText = {t('sku.discount_start_date.tippyContent')}
          tippyText      = {t('sku.discount_start_date.tippyContent')}
        />
        {/* When will the discount begin? We start by assuming any discount is effective immediately, but you can change this. */}
      </td>
      <td>
        <input
          key="140"
          type="date"
          id="discount_start_date_skf"
          name="discount_start_date"
          onChange={handleChange}
          value={formData.discount_start_date}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.discount_end_date.label')}
          // Discount End Date
          labelHtmlFor = "discount_end_date_skf"
          tippyAlertText = {t('sku.discount_end_date.tippyContent')}
          tippyText      = {t('sku.discount_end_date.tippyContent')}
        />
        {/* When will the discount end? */}
      </td>
      <td>
        <input
          key="150"
          type="date"
          id="discount_end_date_skf"
          name="discount_end_date"
          onChange={handleChange}
          value={formData.discount_end_date}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.tax_type.label')}
          // Tax Status
          labelHtmlFor = "tax_type"
          tippyAlertText = {t('sku.tax_type.tippyContent')}
          tippyText      = {t('sku.tax_type.tippyContent')}
        />
        {/* What type of Sales Tax Rate will apply for this item? */}
      </td>
      <td>
        <select
          key="155"
          type="text"
          id="tax_type"
          name="tax_type"
          onChange={handleChange}
          value={formData.tax_type}
        >
          {/* <option value="">Choose one:</option> */}
          <option value="">{t('sku.tax_type.optionValue')}</option>
          {/* <option selected value="0">No Tax</option> // wish this worked... */}
          {/* <option value="0">No Tax</option> */}
          <option value="0">{t('sku.tax_type.optionValue0')}</option>
          {/* <option value="1">Regular</option> */}
          <option value="1">{t('sku.tax_type.optionValue1')}</option>
          {/* <option value="2">Food</option> */}
          <option value="2">{t('sku.tax_type.optionValue2')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>
    <tr
      style={ Constants.SHOW_SHAREABLE ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.shareable.label')}
          // Shareable
          labelHtmlFor = "shareable"
          tippyAlertText = {t('sku.shareable.tippyContent')}
          tippyText      = {t('sku.shareable.tippyContent')}
        />
        {/* Yes/No: Would you like to share this item's information with other Shopping Circles? */}
      </td>
      <td>
        <select
          key="160"
          type="text"
          id="shareable"
          name="shareable"
          onChange={handleChange}
          value={formData.shareable}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('sku.shareable.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('sku.shareable.optionValue1')}</option>
        </select>
      </td>
    </tr>
    <tr
      style={ if_shopper_is_circle_admin ? null : visibilityCollapse }
    >
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('sku.is_hidden.label')}
          // Hidden
          labelHtmlFor = "is_hidden"
          tippyAlertText = {t('sku.is_hidden.tippyContent')}
          tippyText      = {t('sku.is_hidden.tippyContent')}
        />
        {/* Do you want to reduce visibility of this Sku? e.g. not show it as one of the Circle's skus when it's time to plan a trip? etc. */}
      </td>
      <td>
        <select
          key="165"
          type="text"
          id="is_hidden"
          name="is_hidden"
          onChange={handleChange}
          value={formData.is_hidden}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('sku.is_hidden.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('sku.is_hidden.optionValue1')}</option>
        </select>
      </td>
    </tr>
  </tbody>
</Table>
        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
         <div className="oneline-container">
          <input
            key="170"
            type='submit'
            value='Submit'
            disabled={
              formData.price.trim().length === 0
                ||
              formData.price_date.trim().length === 0
            }
          />
          <HeadingBuilder
            tippyInfoText = {
                t('sku.submit.priceWarning10')
              + t('sku.price_date.label')
              + t('sku.submit.priceWarning20')
            }
            // Note: "Price" and "Price 'AsOf' Date" are required fields.
          />
         </div>
        </form>
        { errorsDoExist && <ErrorList /> }
        <BackButton whatToDo={resetSkuTypeFormFlags}/>
      </article>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default SkuForm;