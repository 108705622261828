// client/src/components/Checkbox.js
import React from "react";
import HeadingBuilder from './HeadingBuilder';
  
const Checkbox = ({
  label, tippyAlert, tippyInfo, isChecked, setIsChecked
}) => {
  return (
    <div>
      <label className="container-hug-bottom">
        <HeadingBuilder
          Size           = 'small'
          headingText    = {label}
          tippyAlertText = {tippyAlert}
          tippyText      = {
            (tippyAlert && tippyAlert.length > 0)
              ? tippyAlert
              : tippyInfo
          }
          tippyInfoText  = {tippyInfo}
        />
        &nbsp;
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
          }}
        />
      </label>
    </div>
  );
};

export default Checkbox;

