import React, { useState } from 'react';
import TippyTipIcon from './TippyTipIcon';
import AddlInfoIcon from './AddlInfoIcon';
import ButtonWarning from './ButtonWarning';
import VideoTipIcon from './VideoTipIcon';
import { usePhrasing } from "../hooks/useSkuUtils";
import ShowAlert from '../components/ShowAlert';
import GetIcon from '../components/GetIcon';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styled from 'styled-components';

const Button = styled.button`
  background: lightblue;
  border: none;
  &.hover {
    background: #e7e7e7; color: black;
    transition: 0.3s;
  }
`;

export const HeadingBuilder = ({
  containerType   = "container-headline",  // this css class centers the content
  Size            = "swa-generic",  // heading size/element (h1, h2, etc.)
  headingText     = "",  // text of the heading
  value           = "",  // object of the heading, eg 'gjcircle', displays in <em>
  valueNoEm       = "",  // object of the heading, eg '14', displays normally
  labelHtmlFor    = "",  // adds 'htmlFor' attr to labels w/ tippyText in forms
  goButtonText    = "",  // if present, holds text for a smaller trigger button
  goButton2Text   = "",  // if present, holds text for a second trigger button
  buttonAction    = null, // callback functn becomes onClick action for a button
  linkUrl         = null, // active link to Link to
  linkUrl2        = null, // second active link to Link to
  blueLinkUrl     = null, // active link to Link to
  hrefTo          = null, // active href to Link to

  forceShowAlertText = "0", // allows showing Tippy info before knowing prefs
  tippyAlertText  = "",  // (?) triggers showing this Alert icon and Tippy text
  tippyAlertAudio = "",  // text for tooltip audio - overrides 'tippyAlertText'
  paraPlayText    = "",  // text for paragraph audio - enables audio playback
  showTippyAlert,        // shows (?) Alert text in separate div, not <ShowAlert>
  toggleTippyAlert,      // absence of value will be picked up by '??' operator

  tippyText       = "",  // actual text content for tooltip about the heading

  tippyInfoText   = "",  // (i) overflow tooltip text primary has too much
  tippyInfoAudio  = "",  // text for tooltip audio - overrides 'tippyInfoText'
  showInfoAlert,         // shows (i) Info text in separate div, not <ShowAlert>
  toggleInfoAlert,       // absence of value will be picked up by '??' operator

  bWarningMsg     = "", // message for showing flashes regarding the button
  forceShowBWarning = false, // enables showing warning regardless of prefs
  setShowBWarning = (function () { }), // ButtonWarning will run this with arg
         // of 'false' to flip 'forceShowBWarning' to 'true', i.e.
         // setShowBWarning(false);
         // TODO: Maybe this can be invoked with the arg baked in so that
         // ButtonWarning doesn't have to hardcode the 'false'?
  videoUrl        = "",  // triggers showing video icon and react-player ability
}) => {
  // console.log('hb props', tippyAlertText, headingText, Size, value, valueNoEm, videoUrl, tippyText)
  // console.log('hb props labelHtmlFor', labelHtmlFor)
  // console.log('hb props forceShowBWarning', forceShowBWarning)
  // console.log('hb props bWarningMsg', bWarningMsg)
  // console.log('hb props tippyAlertText', tippyAlertText)
  // console.log('hb props headingText', headingText)
  // console.log('hb props tippyText', tippyText)
  // console.log('hb props tippyInfoText', tippyInfoText)
  // console.log('hb props linkUrl', linkUrl)
  // console.log('hb props videoUrl', videoUrl)

  const [showHbTipAlert, setShowHbTipAlert] = useState(false);
  const toggleHbTipAlert = () =>  setShowHbTipAlert(! showHbTipAlert);

  const [showHbInfoAlert, setShowHbInfoAlert] = useState(false);
  const toggleHbInfoAlert = () =>  setShowHbInfoAlert(! showHbInfoAlert);

  const [showHbBWarning, setShowHbBWarning] = useState(true);
  const toggleHbBWarning = () =>  setShowHbBWarning(! showHbInfoAlert);

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const {headingPlusEmValue, headingPlusValue,
    getAudioText, headingPlusPlayAudio,
  } = usePhrasing();

  const formattedHeadingText =
    value.length > 0
      ? headingPlusEmValue({
          heading: headingText,
          value: value
        })
      : valueNoEm.length > 0
        ? headingPlusValue({
            heading: headingText,
            value: valueNoEm
          })
        : paraPlayText.length > 0
          ? headingPlusPlayAudio({
              heading:  headingText,
              origText: getAudioText({originalText: paraPlayText})
            })
          : headingText;

  const forceShowAlertTextBoolean =  forceShowAlertText == "0"  ? false : true;

  return (
    <>
      {/* div allows designating a class that puts the
      headline and other help icons on the same line. */}
      <div className={containerType}>
        <Size>
          {tippyText.length > 0
            ? <Tippy content= {tippyText} >
                <span>
                  {labelHtmlFor.length > 0
                    ? <label htmlFor={labelHtmlFor}>{formattedHeadingText}</label>
                    : null
                  }
                  {(buttonAction != null  &&  goButtonText.length > 0)
                    ? <>
                        {formattedHeadingText}
                        &nbsp;&nbsp;
                        <button onClick={buttonAction}>
                          <GetIcon toGet={goButtonText} />
                        </button>
                      </>
                    : null
                  }
                  {(buttonAction != null  &&  goButtonText.length === 0)
                    ? <button onClick={buttonAction}>
                        <>{formattedHeadingText}</>
                      </button>
                    : null
                  }
                  {(hrefTo != null  &&  goButtonText.length > 0)
                    ? <>
                        {formattedHeadingText}
                        &nbsp;&nbsp;
                        <a href={hrefTo}>
                          <button className="text-black">
                            <GetIcon toGet={goButtonText} />
                          </button>
                        </a>
                      </>
                    : null
                  }
                  {(hrefTo != null  &&  goButtonText.length === 0)
                    ? <a href={hrefTo}>
                        <button className="text-black">
                          <>{formattedHeadingText}</>
                        </button>
                      </a>
                    : null
                  }
                  {(linkUrl != null  &&  goButtonText.length > 0)
                    ? <>
                        <>{formattedHeadingText}</>
                        &nbsp;&nbsp;
                        <Link to={linkUrl}>
                          <button className="text-black">
                            <GetIcon toGet={goButtonText} />
                          </button>
                        </Link>
                        {(linkUrl2 != null  &&  goButton2Text.length > 0)
                          ? <>
                              &nbsp;
                              <Link to={linkUrl2}>
                                <button className="text-black">
                                  <GetIcon toGet={goButton2Text} />
                                </button>
                              </Link>
                            </>
                          : null
                        }
                      </>
                    : null
                  }
                  {(linkUrl != null  &&  goButtonText.length === 0)
                    ? <Link to={linkUrl}>
                        <button className="text-black">
                          <>{formattedHeadingText}</>
                        </button>
                      </Link>
                    : null
                  }
                  {blueLinkUrl != null
                    ? <Link to={blueLinkUrl}>
                        <Button type="button">
                          <>{formattedHeadingText}</>
                        </Button>
                      </Link>
                    : null
                  }
                  {labelHtmlFor.length === 0
                    &&  buttonAction == null
                    &&  hrefTo == null
                    &&  linkUrl == null
                    &&  blueLinkUrl == null
                      ? <>{formattedHeadingText}</>
                      : null
                  }
                </span>
              </Tippy>
            : formattedHeadingText
          }
        </Size>

        <TippyTipIcon
          alertMessage={tippyAlertText}
          showTippyAlert={showTippyAlert ?? showHbTipAlert}
          forceShowAlertTextBoolean={forceShowAlertTextBoolean}
          toggleTippyAlert={toggleTippyAlert ?? toggleHbTipAlert}
        />

        {tippyInfoText &&
        <>&nbsp;
        <AddlInfoIcon
          alertMessage={tippyInfoText}
          showInfoAlert={showInfoAlert ?? showHbInfoAlert}
          toggleInfoAlert={toggleInfoAlert ?? toggleHbInfoAlert}
        />
        </>}

        <ButtonWarning
          bWarningMsg        = {bWarningMsg}
          forceShowBWarning  = {forceShowBWarning}
          setShowBWarning    = {setShowBWarning}
          iconShowBWarning   = {showHbBWarning}
          iconToggleBWarning = {toggleHbBWarning}
        />

        {
          videoUrl.length > 0
            ? <VideoTipIcon
                showVideoPlayer={showVideoPlayer}
                showVideoPlayerSetter={setShowVideoPlayer}
              />
            : null
        }
      </div>

      {/*
        A 'BWarning' displays when two conditions are true:
        1) The caller must have set 'forceShowBWarning' to be 'true'.
        2) The 'ButtonWarning' component has not unset the default of 'true'
           for 'showHbBWarning'.
      */}
      {forceShowBWarning
        ? <ShowAlert showAlert = {showHbBWarning}
                      alertMsg = {bWarningMsg} />
        : null
      }

      {/* If caller did not pass params for monitoring buttons' boolean status
      then do 'ShowAlert's here: */}
      {showTippyAlert == null
        ? <ShowAlert showAlert = {showHbTipAlert}
                      alertMsg = {tippyAlertText}
                    alertAudio = {tippyAlertAudio} />
        : null
      }
      {showInfoAlert == null
        ? <ShowAlert showAlert = {showHbInfoAlert}
                      alertMsg = {tippyInfoText}
                    alertAudio = {tippyInfoAudio} />
        : null
      }

      {/* Now present the player itself if there's a video */}
      {
        (videoUrl.length > 0  && showVideoPlayer)
          ? <div className="container-headline">
              <ReactPlayer
                url={videoUrl}
                controls={true}
                className="react-player"
              />
            </div>
          : null
      }
    </>
  )
}

export default HeadingBuilder;