// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (table data)
import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import * as Constants from '../constants'
import { showRoundedAbsAmt, findAbsAmtAsPct } from '../utils';
import { useStyles } from "../hooks/useStyles";
import { useFormats, usePricing } from "../hooks/useSkuUtils";
import { OutlayAmt } from '../components/OutlayAmt';
import { useTranslation } from "react-i18next";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? Constants.O_BOX_GREEN : Constants.O_BOX_RED};
  border: none;
  &.hover {
    background: #e7e7e7; color: black;
    transition: 0.3s;
  }
`;

// We have already verified "if_shopper_is_circle_admin", so don't need to check.
// Can't be abused because backend verifies that only the shopper or admin is updating.
export const AdminChangeOtherOrder = ({
    order,
    trip,
    sku,
    skuPrice
   }) => {
  console.log("acooC skuPrice", skuPrice)
  // FIX ME: need to use signedIn here?
  const { signedIn, orderItems, changeOrderItem, showOutlayWithTax } = useContext(ShopperContext);
  console.log("ooD orderItems", orderItems)
  const { t } = useTranslation();
  const { editBoxStyle } = useStyles();
  const { checkDecimalInput } = useFormats();
  const { chooseDecimalScaleQty } = usePricing();

  const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
  const isGranular =  sku.type == "GranularItem" ? true : false;
  const isWeighty  =  sku.type == "WeightyItem" ? true : false;

  if (isWeighty && (!sku.typical_weight || typeof sku.typical_weight == 'undefined')) {
    console.log("ooNG weightySku but no typical weight")
  }

  const [orderAmtButtonIsPink, setOrderAmtButtonIsPink] = useState(false);
  const [plusButtonIsGreen, setPlusButtonIsGreen] = useState(true);
  const [unitQty, setUnitQty] = useState(
    isGranular
          ? 0
          : Math.round(order.abs_amount * 100)/100
  );
  console.log("oief unitQty", unitQty);

  const [absAmtAsPct, setAbsAmtAsPct] = useState(
    findAbsAmtAsPct({topNum: order.abs_amount, sku})
  );
  console.log("oief absAmtAsPct", absAmtAsPct);

  const handleClick = (e) => {
    setOrderAmtButtonIsPink(false);
    setPlusButtonIsGreen(true);
    console.log("oief hc sku1", order);
    console.log("oief hc sku1.5", order.id);
    console.log("oief hc sku2", e.target.value);
    const orderItem = {
      abs_amount: isGranular ? (parseFloat(absAmtAsPct) / 100) : unitQty
    };
    changeOrderItem(orderItem, order.id);
  }

  const handleOIUnitQtyChange = (e) => {
    const newUnitQty = checkDecimalInput( {numInput: e.target.value} )

    setOrderAmtButtonIsPink(true);
    setPlusButtonIsGreen(false);
    console.log("hoiuqc plusButtonIsGreen", plusButtonIsGreen);
    console.log("hoiuqc nuq", newUnitQty);
    setUnitQty(() => newUnitQty);
    if (isDiscrete || isWeighty) {
      setAbsAmtAsPct( () => findAbsAmtAsPct({topNum: newUnitQty, sku}) )
    }
  }

  const handleOIAbsAmtAsPctChange = (e) => {
    const newAbsAmtAsPct = checkDecimalInput( {numInput: e.target.value} )

    setOrderAmtButtonIsPink(true);
    setPlusButtonIsGreen(false);
    console.log("hoiaqapc plusButtonIsGreen", plusButtonIsGreen);
    console.log("oief aqap1", absAmtAsPct);
    console.log("oief hoiaqapc naqap", newAbsAmtAsPct);
    setAbsAmtAsPct( () => newAbsAmtAsPct )
    console.log("oief aqap2", absAmtAsPct);
  }

    const adjustedAbsAmtAsPct =
      (isWeighty && sku.typical_weight)
        ? absAmtAsPct / sku.typical_weight
        : absAmtAsPct;

    return (
      <>
      <td>
        {/* "UnitQty" */}
        {
          (isDiscrete || isWeighty)
            ? <span><NumericFormat displayType="input"
                type="text"
                name="unitQty"
                onChange={e => handleOIUnitQtyChange(e)}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleClick(e)
                  }
                }}
                value={unitQty}
                decimalScale={ chooseDecimalScaleQty({sku}) }
                style={ editBoxStyle({ cellQty: unitQty,
                  changeColorOfBox: orderAmtButtonIsPink,
                  normalBoxColor:   Constants.O_BOX_YELLOW,
                  editBoxColor:     Constants.O_BOX_PINK
                }) }
              /></span>
            : <>--</>
        }

        { isWeighty && sku.units }
      </td>

      <td>
        {/* "Unit Cost" - only the main order needs to show this */}
      </td>

      <td>
        {/* "AbsAmt" */}
        {
          isGranular
            ? <span><NumericFormat displayType="input"
                type="text"
                name="absAmtAsPct"
                onChange={e => handleOIAbsAmtAsPctChange(e)}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleClick(e)
                  }
                }}
                value={absAmtAsPct}
                decimalScale={ chooseDecimalScaleQty({sku}) }
                style={ editBoxStyle({ cellQty: absAmtAsPct,
                  changeColorOfBox: orderAmtButtonIsPink,
                  normalBoxColor:   Constants.O_BOX_YELLOW,
                  editBoxColor:     Constants.O_BOX_PINK
                }) }
              />%</span>
            : <>{showRoundedAbsAmt({ adjustedAbsAmtAsPct }) + "%"}</>
        }
      </td>

      <td className = "oneline-container">
        <OutlayAmt
          trip        = {trip}
          sku         = {sku}
          sku_price   = {skuPrice}
          order       = {order}
          decimal_amt = {absAmtAsPct / 100}
          showOutlayWithTax = {showOutlayWithTax}
        />
      </td>

      <td>
        {/* "O" button: */}
  {/* <Tippy content='Click the pink "O" sign (or press Enter) to update the database with the amount the shopper wants of the product. It will turn back to green upon successful update.'> */}
  <Tippy content= {t('adminChangeOtherOrder.o_col.tippyContentA') + Constants.O_COL_SYM + t('adminChangeOtherOrder.o_col.tippyContentB')} >
    <Button type="button" primary={plusButtonIsGreen} value={order.id} onClick={handleClick}>{Constants.O_COL_SYM}</Button>
  </Tippy>
      </td>

    </>
  )

};
      
export default AdminChangeOtherOrder;