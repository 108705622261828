import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { SkusFound } from './SkusFound';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { ErrorList } from './ErrorList';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import styles from '../css/trTdFirstChild.module.css';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import encodeurl from "encodeurl";

export const SkuSearch = () => {
  const { signedIn, foundSkus, searchSkus, circles } = useContext(ShopperContext);
  const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
  const { setJustGotClearToFalse } = useContext(ShopperContext);

  const {circle_id} = useParams();
  console.log('SkuSearch.js circle_id: ', circle_id);
  const circle = circles.find((c) =>  c.id === circle_id ) || {};

  const { tableShow, tableData } = useStyles();
  const { t } = useTranslation();

  const initialState = {
    brand: '',
    label: '',
    identifier: '',
    store_label: '',
    detail: ''
  };

  const { formData, handleChange, reset, editFormValues } = useForm(initialState);

  const [searchInProgress, setSearchInProgress] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  
  useEffect(() => {
      setShowSearchResults(false);
  }, [formData]);

  if (signedIn) {
    const handleSubmit = (e) => {
      e.preventDefault();
            
      // clear out any error messages, success statuses
      dismissErrorsList();
      setErrorsDoExistToFalse();
      setJustGotClearToFalse();
      setSearchInProgress(true);
  
      console.log('formData for editSku: ', formData);

      const query = Object.entries(formData)
        .filter(([key, value]) => value.length > 0)
        .map(([key, value]) => `${key}=${encodeurl(value)}`)
        .join("&");
      console.log('encodeurl of formdata: ', query);

      // Delay showing a count of foundSkus until 'searchSkus' is done.
      // Otherwise, a placeholding/wrong value appears right away (not helpful.)
      // In fact, use that time to show a "Searching..." message.
      (async function () {
        await searchSkus(query);
        setSearchInProgress(false);
        setShowSearchResults(true);
      })();
      console.log('skus found: ', foundSkus);

      // If there is an error we want to preserve formData and show the message.
    }

    const skuSearchHeading =
        t('sku.searchOtherCirclesForSku.tippyContent')
      + t('sku.searchOtherCirclesForSku.tippyContentFormNote');

    return (
      <>
        <article>
          <hr/>
          <HeadingBuilder
            Size        = 'h1'
            headingText = {t('sku.searchOtherCirclesForSku.headingText')}
            value       = {circle.identifier}
            // Add Sku for Circle: uipuy
          />
          <HeadingBuilder
            Size           = 'h3'
            headingText    = {t('sku.formPart.skuSearchHeadlineLabel')}
            // Search for Skus matching:
            tippyAlertText = {skuSearchHeading}
            tippyText      = {skuSearchHeading}
          />
          <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.store_label.label')}
          // Store
          labelHtmlFor   = 'store_label_sks'
          tippyAlertText = {t('sku.store_label.tippyContentSearch')}
          tippyText      = {t('sku.store_label.tippyContentSearch')}
          // Every Sku has info about the Store where it is purchased...
        />
      </td>
      <td>
        <input
          key="10"
          type="text"
          id="store_label_sks"
          name="store_label"
          onChange={handleChange}
          value={formData.store_label}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.identifier.label')}
          // ID
          labelHtmlFor   = 'identifier_sks'
          tippyAlertText = {t('sku.identifier.tippyContent')}
          tippyText      = {t('sku.identifier.tippyContent')}
          // Short identifier for the item such as a store code, barcode, or ...
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          id="identifier_sks"
          name="identifier"
          onChange={handleChange}
          value={formData.identifier}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.brand.label')}
          // Brand
          labelHtmlFor   = 'brand_sks'
          tippyAlertText = {t('sku.brand.tippyContent')}
          tippyText      = {t('sku.brand.tippyContent')}
          // e.g. Green Giant, General Mills, Heinz.
        />
      </td>
      <td>
        <input
          key="30"
          type="text"
          id="brand_sks"
          name="brand"
          onChange={handleChange}
          value={formData.brand}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.label.label')}
          // Name/Label
          labelHtmlFor   = 'label_sks'
          tippyAlertText = {t('sku.label.tippyContentSearch')}
          tippyText      = {t('sku.label.tippyContentSearch')}
          // Short, quick name of the item
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label_sks"
          name="label"
          onChange={handleChange}
          value={formData.label}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('sku.detail.label')}
          // Details
          labelHtmlFor   = 'detail_sks'
          tippyAlertText = {t('sku.detail.tippyContentSearch')}
          tippyText      = {t('sku.detail.tippyContentSearch')}
          // Additional details/info about the item
        />
      </td>
      <td>
        <textarea
          key="50"
          id="detail_sks"
          name="detail"
          onChange={handleChange}
          value={formData.detail}
        />
      </td>
    </tr>
  </tbody>
</Table>
          {/* TODO: ?Will translating Submit be handled automatically by html?
              https://www.w3.org/International/wiki/Locale-based_forms */}
            <input key="170" type='submit' value='Submit' />
          </form>
          { errorsDoExist && <ErrorList /> }

          <Table responsive>
            <thead>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/* Button to reset search fields. Only appears if any search text. */}
                  { [
                      formData.brand,
                      formData.label,
                      formData.identifier,
                      formData.store_label,
                      formData.detail
                    ].some( (element) => element.length > 0 )
        
                    ? <HeadingBuilder
                        containerType  = 'oneline-container-left'
                        headingText    = {t('sku.reset.buttonText')}
                        // Reset Filters
                        buttonAction   = {reset}
                        tippyAlertText = {t('sku.reset.tippyContent')}
                        tippyText      = {t('sku.reset.tippyContent')}
                        // Click this button to blank out the Search fields.
                      />
                    : null
                  }
                </td>
                <td>
                  { searchInProgress
                     ? <HeadingBuilder
                         headingText    = {t('global.searching')}
                         // Searching...
                       />
                     : showSearchResults
                       ? <HeadingBuilder
                           headingText    = {t('sku.searchOtherCirclesForSku.matchCount')}
                           valueNoEm      = {foundSkus.length.toString()}
                           // Skus found: 14
                           tippyAlertText = {t('sku.searchOtherCirclesForSku.tippyContentMatchCount')}
                           tippyText      = {t('sku.searchOtherCirclesForSku.tippyContentMatchCount')}
                         />
                       : null
                  } 
                </td>
                <td>
                  <BackButton lockRight={false}/>
                  {/* <BackButton whatToDo={resetSkuTypeFormFlags}/> */}
                </td>
              </tr>
            </tbody>
          </Table>
        </article>
        <article>
          { foundSkus
            ? <SkusFound
                circle_id={circle_id} 
                otherSkus={foundSkus}
                showSearchResults={showSearchResults}
              />
            : <h3>{t('sku.noSkus')}</h3>
              // No Skus
          }
        </article>
      </>
      )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default SkuSearch