import React, { useState, useContext, useEffect } from 'react';
import { ShopperContext } from '../context/shopper';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import HeadingBuilder from './HeadingBuilder';
import InvitationForm from './InvitationForm'
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";

// Don't use this component unless shopper is leader of the circle.
export const CircleDeleteForm = () => {
    const { signedIn, circles, deleteCircle,
            setJustGotClearToFalse, setClientReferenceId
          } = useContext(ShopperContext);
    console.log('cef circles: ', circles)

    const [invitationFormFlag, setInvitationFormFlag] = useState(false);
    const lightUpAddInvitationButton = () => {
        setInvitationFormFlag(false);
    }

    const navigate = useNavigate();
    const {id} = useParams();
    console.log('cef id: ', id)
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const clear_client_ref_id = searchParams.get("clear_client_ref_id") === '1';
    // console.log('clear_client_ref_id is ', clear_client_ref_id);

    // If 'delete' was from a Stripe buy button component, unset clientRefId
    // that was made after creating the circle.
    // It flashes visible for a while until the 'useEffect' phase sweeps it out.
    useEffect(() => {
      if (clear_client_ref_id) {
        setClientReferenceId('');
        setJustGotClearToFalse();
      }
    }, [clear_client_ref_id, setClientReferenceId, setJustGotClearToFalse]);

    const circleObj = circles.find(c => c.id == id);
    console.log('cef dcircleObj: ', circleObj)
    if (circleObj == null) {
      console.log('cef dcircleObj2: ', circleObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'circleObj' to be 'undefined'.
      // Let's go back to the 'circles' page.
      window.location.replace('/circles/');
      // not sure why, but code below continues executing.
    }

    async function removeCircle (e) {
      // TODO: is event (e) useful for anything?
  
      // Delete a circle.
      // Backend only allows circle's leader to delete a circle.
  
      console.log("dc before di", Date.now() );
      const rc = await deleteCircle(circleObj.id);
      console.log("dc rc", rc);
      console.log("dc after dt", Date.now() );
  
      // Rails should have deleted the circle's circle_shoppers, invitations,
      // trips, skus, orderItems, prices
      // TODO: Need some error handling here?

      // Let's go back to the 'circles' page.  "navigate('/circles/')" makes
      // the screen right. Without the 'replace' part that follows,
      // the CircleDeleteForm gets triggered directly leading to an
      // ugly error message. And not obvious on the screen what to do next,
      // how to recover. (The screen is blank with a url mentioning 'delete'.)
      navigate('/circles/', {replace: true});

      // Refresh since state for other pages like '/trips' (via 'Trips') choke
      // when the trips they still know about in state aren't there.
      navigate(0)
    }

    const deleteButtonHeading =
      <>{t('circle.removeCircle.buttonLabel')} '{circleObj.identifier}'</>;

    if (signedIn) {
      return (
        <article>
          {invitationFormFlag
            ? null
            : <>
          <HeadingBuilder
            Size = 'h3'
            headingText = {t('circleDeleteForm.formPart.headlineLabel')}
            // Delete Circle: oipuy
            value = {circleObj.identifier}
          />

          <HeadingBuilder
            Size = 'h1'
            headingText =  {t('global.areYouSure')}
            // Are You Sure?
            tippyAlertText = {t('circle.removeCircle.tippyContent')}
            tippyText =      {t('circle.removeCircle.tippyContent')}
          />
              <br/>
            </>
          }

          {invitationFormFlag
            ? <InvitationForm
              inviteType = {2}
              circle_id = {circleObj.id}
              circle_identifier = {circleObj.identifier}
              lightUpAddInvitationButton = {lightUpAddInvitationButton} />
            : <HeadingBuilder
                headingText    = {t('circle.inviteLeaderButton.label')}
                // Invite a Shopper to become Leader of the Circle
                buttonAction   = {() => setInvitationFormFlag(true)}
    
                tippyAlertText = {t('circle.inviteLeaderButton.tippyContent')}
                tippyText      = {t('circle.inviteLeaderButton.tippyContent')}
              />
          }
          <br/>

          {invitationFormFlag
            ? null
            : <>
          {/* This 'Delete' button only works if shopper is leader of the circle. */}
          <HeadingBuilder
            headingText    = {deleteButtonHeading}
            // Yes, delete Circle 'oipuy'
            buttonAction   = {removeCircle}

            tippyAlertText = {t('circle.removeCircle.tippyContent')}
            tippyText      = {t('circle.removeCircle.tippyContent')}
          />
          {/* { errorsDoExist && <ErrorList /> } */}
          <br/>
          <BackButton />
            </>
          }
        </article>
      )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default CircleDeleteForm;