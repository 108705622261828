// Don't apply 'HeadingBuilder' for the icon for the Feedback form. 'Tippy contents' show a (styled button)
import React, { useContext, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { useUtils } from "../hooks/useUtils";
import { RxChatBubble } from "react-icons/rx";
//import i18n from '../i18n';
//import LocaleContext from '../LocaleContext';
import { HeadingBuilder } from './HeadingBuilder';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

const link = {
    width: '100px',
    padding: '12px',
    margin: '0 6px 6px',
    textDecoration: 'none',
    color: 'white',
    background: 'blue'
}

const activeStyle = {
    width: '100px',
    padding: '12px',
    margin: '0 6px 6px',
    textDecoration: 'none',
    color: 'white',
    background: 'darkblue'
};

const Navbar = () => {
    const {shopper, signout, signedIn,
      trips,
      inSkuMenu,
      showShoppingList,
      setShowShoppingListToTrue,
      lastTripSeenId, setLastTripSeenId,
      showFeedbackForm,
      setShowFeedbackForm
        }
        = useContext(ShopperContext)

    const location = useLocation();
    // console.log('nav location', location);
    // console.log('nav location pathname', location.pathname);
    const tripUuidRegex = /^\/trips\/[a-z0-9-]{36,36}$/;
    const isMatchForTrip = tripUuidRegex.test(location.pathname);
    // console.log('nav location isMatchForTrip:', isMatchForTrip);

    // console.log('nav lastTripSeenIdA', '"'+lastTripSeenId+'"')

    // Verify that lastTripSeenId is one of shopper's trips. Maybe was deleted?
    const lastTripSeenIdString =
      lastTripSeenId && lastTripSeenId.length > 0
        ? lastTripSeenId.toString()
        : '';
    const backTripObj =  trips.find(t => t.id === lastTripSeenIdString);

    const mostRecentTrip =
      backTripObj
        ? { buttonLabel: 'Trip', initUrl: 'trips', id: lastTripSeenId, }
        : null;
    // the 'initUrl' value here is the same as for 'tripUuidRegex'
    // console.log('nav backTrip mostRecentTrip', mostRecentTrip)

    // localStorage holds the authoritative value of 'lastTripSeenId'.
    // Get it and save its value to state.
    useEffect(() => {
      // If shopper has multiple accounts, which means some/most/all trips of
      // circles in one account will not be accessible when logged in to the
      // other account, then we have to be account-specific when tracking
      // and reporting what the most recent Shopping Trip viewed was.

      // We want to populate a state variable with a value from localStorage.
      // When shopper is logging out, there won't be a value for 'shopper' or
      // the need to set a state variable. Only proceed if 'shopper' is defined.
      if (shopper) {
        // Set lastTripSeenId state for the current shopper with a value from
        // localStorage.

        // get value from localStorage...
        const lastTripSeenForShopperKey =
                                      Constants.LAST_TRIP_SEEN_FOR + shopper.id;
        // console.log('nav lastTripSeenForShopperKey', lastTripSeenForShopperKey);
        // LAST_TRIP_SEEN_FOR_018e6e28-b9e8-75a7-891e-099582a4b3b3

        const data = window.localStorage.getItem(lastTripSeenForShopperKey);
        // console.log('nav data:', data, data.length)
        // returns the trip_id in quotes: "018e59f5-5960-7007-9ee7-c3782e57622f"
        // n.b. 'data', if it exists, can only be a string.

        const match =
          // if there is data to check...
          !!data
            // check 'data' (without the quotes) for match of HAS_UUID_PROFILE
            ? (JSON.parse(data).match(Constants.HAS_UUID_PROFILE) ? true : false)
            : false;

        if (!match) {
          setLastTripSeenId('');
        } else {
          const trip_id = JSON.parse(data);
          // console.log('nav trip_id:', trip_id)
          // returns trip_id w/o quotes: 018e59f5-5960-7007-9ee7-c3782e57622f

          setLastTripSeenId(trip_id);
        }
      }
    }, [setLastTripSeenId, shopper]);

    const { toMmmDdYyyy, toHmmA } = useUtils();

    // Prepare tippy text for the 'backTrip' button, if nec.
    const tippyTripDescription =
    backTripObj
      ? backTripObj.label
        + ' -- ' + toMmmDdYyyy({dateString: backTripObj.day, weekday: 'short'})
        + ', '   + toHmmA({timeString: backTripObj.hour})
      : '';

    const navigate = useNavigate();
    const { t } = useTranslation();

    const toggleShowFeedbackForm = () => {
      setShowFeedbackForm(! showFeedbackForm);
    }

    // This will be useful if/when we support other languages:
    // const { locale } = useContext(LocaleContext);
    // function changeLocale (l) {
    //   if (locale !== l) {
    //     i18n.changeLanguage(l);
    //   }
    // }

    const signoutShopper = () => {
        fetch('/api/signout', {
            method: 'DELETE'
            // , headers: { 'Content-Type': 'application/json' }  // don't need
        })
        .then(() => {
            navigate('/')
            signout()
        })
    }
console.log("cl", shopper);
console.log("cl signedIn", signedIn);

    // info re NavLink: https://reactrouter.com/en/v6.3.0/api#navlink
    // https://stackoverflow.com/a/64802678
    // "Array.isArray" part comes from https://stackoverflow.com/a/24403771
    if (signedIn) {
        const navName = shopper.firstname || shopper.lastname || shopper.shortname;

        return (
            <nav>
                <h3>
        {/* Hello uuu! (uiui) */}
        {t('navbar.formPart.headlineLabel')} {navName}! ({shopper.identifier})
                </h3>

              <div className='container-hug-bottom'>

               {/* This 'div' lets us stack the 'Back' and 'backTrip' buttons */}
               <div>
                <HeadingBuilder
                  containerType  = 'container-headline mb-2'
                  headingText    = {t('global.label.back')}
                  // Back
                  tippyText      = '-'
                  buttonAction   = { () => navigate(-1) }
                />
                {
    // Unless we are viewing a trip's Shopping List,
    // and if we have access to the most recently viewed Shopping List,
    // then show the 'backTrip' button
                  !(isMatchForTrip && showShoppingList)
                  && mostRecentTrip
                    ? <HeadingBuilder
                        containerType  = 'container-headline mb-1'
                        headingText    = {mostRecentTrip.buttonLabel}
                        // Trip

                     // tippyAlertText = {tippyTripDescription}  // ugly, so no
                        tippyText      = {tippyTripDescription}
                        // Stores -- Sat, Jul 27, 2024, 2:00 PM  

    // Here comes the 'backTrip' button. But, how will it work? Usually
    // we redirect to a link that opens the Shopping List view for the trip.
    // But, if we had been on the Shopping List view and from there had opened
    // the Maybe-List (the sku menu), then we are already at the correct url.
    // Now it's a matter of toggling the 'showShoppingList' state variable
    // to switch back to viewing the Shopping List.
    // The 'SkuMenu' component has a 'useEffect' hook that communicates
    // its 'open' status via state variable 'inSkuMenu'. It becomes 'true'
    // upon first opening 'SkuMenu', and when 'SkuMenu' unmounts, i.e. when the
    // shopper navigates away from 'SkuMenu', cleanup of the 'useEffect' sets
    // the status back to 'false'. We can use that knowledge to enable action
    // of either 'buttonAction' or 'linkUrl'. Evaluating one of these as 'null'
    // lets the other take effect.
    buttonAction = {inSkuMenu ? setShowShoppingListToTrue : null}
    linkUrl      = {inSkuMenu ? null :
                              `/${mostRecentTrip.initUrl}/${mostRecentTrip.id}`}
                      />

                    : null
                }
               </div>

                &nbsp;
                &nbsp;

                <NavLink to='/'
                    style={({isActive}) =>
                        isActive ? activeStyle : link
                    }
                >{t('global.label.home')}</NavLink>
                {/* Home */}

                <NavLink to='/circles'
                    style={({isActive}) =>
                        isActive ? activeStyle : link
                    }
                >{t('global.label.circles')}</NavLink>
                {/* Circles */}

                <NavLink to='/skus'
                    style={({isActive}) =>
                        isActive ? activeStyle : link
                    }
                >{t('global.label.skus')}</NavLink>
                {/* Skus */}

                <NavLink to='/trips'
                    style={({isActive}) =>
                        isActive ? activeStyle : link
                    }
                >{t('global.label.trips')}</NavLink>
                {/* Trips */}

                {/* TODO: These are buttons that enable changes to locale (language): */}
                {/* <button onClick={() => changeLocale('en')}>En</button> */}
                {/* <button onClick={() => changeLocale('es')}>Es</button> */}

                &nbsp;
                &nbsp;
                <button onClick={signoutShopper}>
                  {t('global.label.sign_out')}
                  {/* Sign out */}
                </button>
                &nbsp;
                &nbsp;
                <Tippy content= {
                  showFeedbackForm 
                    // Click here to hide Feedback form
                    ? t('feedback.feedbackMessage.tippyContentCloseFeedback')
                    // Want to tell us something? Click here!
                    : t('feedback.feedbackMessage.buttonTippyContent')
                } >
                  <button 
                    style={{
                       border: "none",
                       margin: 5,
                       padding: 0,
                       background: showFeedbackForm ? Constants.O_BOX_RED : "none"
                    }} 
                    onClick={toggleShowFeedbackForm}
                  >
                    {/* show speech bubble icon */}
                    <span><RxChatBubble/></span>
                  </button>
                </Tippy>
              </div>
                <hr />

            </nav>
        )
    } else {
        return (
            <div>
                <nav className="navbar navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                          {t('global.label.shopWeAll')}{Constants.TM_SYM}
                          {/* ShopWeAll&#8482; */}
                        </a>
                        <form className="d-flex">
                            <NavLink to='/signin' className="px-2" >
                              <button type="button" className="btn btn-info btn-sm">
                                {t('global.label.sign_in')}
                                {/* Sign In */}
                              </button>
                            </NavLink>
                            <NavLink to='/signup'>
                              <button type="button" className="btn btn-info btn-sm">
                                {t('global.label.sign_up')}
                                {/* Sign Up */}
                              </button>
                            </NavLink>
                        </form>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navbar;
