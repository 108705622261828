import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShopperContext } from '../context/shopper'
import * as Constants from '../constants';
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import { usePhrasing } from "../hooks/useSkuUtils";
import { doesArrayExist } from '../utils';
import TripLink from '../components/TripLink'
import Checkbox from '../components/Checkbox';
import HeadingBuilder from '../components/HeadingBuilder'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

const Trips = () => {
  const { shopper, circles, circleShoppers, trips, signedIn,
        } = useContext(ShopperContext);

  const { tcircle_id } = useParams();
  console.log( 'tci c', tcircle_id);

  const { s_by_identifier_postal_code, any_identifiers_in_multiple_postal_codes,
    is_shopper_a_circle_admin, is_shopper_admin_of_some_circle } = useUtils();
  const { tableShow } = useStyles();
  const { tableShowNarrow, textAlignLeft } = useStyles();
  const { getTippyPostalCode } = usePhrasing();
  const { t } = useTranslation();
  const [showTripDespiteHidden, setShowTripDespiteHidden] = useState(false)

  // See Skus.js for discussion of content of 'useEffect' here.
  const [showCircleZipCodes, setShowCircleZipCodes] = useState(true);
  useEffect(() => {
    setShowCircleZipCodes(
      any_identifiers_in_multiple_postal_codes({circles}) ? true : false
    );
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const [showRemoveTripColumn, setShowRemoveTripColumn] = useState(false);
  const toggleShowRemoveTripColumn = () => {
      setShowRemoveTripColumn(() => !showRemoveTripColumn);
      console.log("srtc", showRemoveTripColumn);
  };

  const removeTripButtonTippyText =
    t('trip.toggleButtonToRemoveTrip.tippyContentA') +
    t('global.clickCheckboxToShowHideColumnTippyText') +
    t('trip.toggleButtonToRemoveTrip.tippyContentC') +
    t('global.qWantToProceedTippyText');

  if (signedIn) {
    const toggleShowZipCodes = () => {
      setShowCircleZipCodes( () => !showCircleZipCodes );
    };

    const toggleShowingHiddenTrips = () => {
      setShowTripDespiteHidden( () => !showTripDespiteHidden );
    };

      function s_by_circle_then_tripDayHr( a, b )
      {
        //console.log("sbitd", a)
        if ( a.circle.identifier.toLowerCase() < b.circle.identifier.toLowerCase()){
          return -1;
        }
        if ( a.circle.identifier.toLowerCase() > b.circle.identifier.toLowerCase()){
          return 1;
        }
        if ( a.circle.postal_code < b.circle.postal_code){
          return -1;
        }
        if ( a.circle.postal_code > b.circle.postal_code){
          return 1;
        }
        if ( a.day < b.day ){
            return -1;
        }
        if ( a.day > b.day ){
          return 1;
        }
        if ( a.hour < b.hour ){
            return -1;
        }
        if ( a.hour > b.hour ){
          return 1;
        }
        return 0;
      }
    
      function trips_exist(array) {
        if (array.length > 0) {
          return true
        }
        return false
      }
  
      console.log('CirctripsA', trips);
      //console.log('CirctripsB', trips);
      const tripsList =
          trips_exist(trips)
          ?
            trips.filter(
                // If a trip is not hidden, show it! Else, it's hidden, so
                // only show it if the shopper is an admin of trip's circle.
                // 'showTripDespiteHidden' lets admins control whether to
                // even view 'Hidden' trips.
                trip => !trip.is_hidden  ||  (showTripDespiteHidden &&
                  is_shopper_a_circle_admin({
                    cShoppers: circleShoppers, circle_id: trip.circle.id, shopper_id: shopper.id
                  })
                )
              )
              .filter(
                trip => tcircle_id  ?  trip.circle.id == tcircle_id  :  true
              )
              .sort( s_by_circle_then_tripDayHr )
              .map(trip => {
                console.log('t_before', trip);
                const t = {...trip};
                console.log('t_after', t);
    
                return <TripLink
                  key  = {t.id}
                  trip = {t}
                  if_shopper_is_circle_admin = {
                    is_shopper_a_circle_admin({
                      cShoppers: circleShoppers, circle_id: trip.circle.id, shopper_id: shopper.id
                    })
                  }
                  showCircleZipCodes   = {showCircleZipCodes}
                  showRemoveTripColumn = {showRemoveTripColumn}
                />
              })
          : null;
      console.log('Trips.js tripsList: ', tripsList);

      const mkListOfAddTripLinks =
      ({
            circs = circles,
            cShoppers = circleShoppers,
            shopper_id = shopper.id
        }) => {
            console.log('Circs', circs);
            console.log('cShoppers', cShoppers);
            console.log('shopper_id', shopper_id);

            const shopper_admins_these_circles = circs
            .filter((circle) => {
              console.log('circle_id', circle.id);

              //console.log('response', is_shopper_a_circle_admin({ cShoppers, circle_id: circle.id, shopper_id }) );
              return is_shopper_a_circle_admin({ cShoppers, circle_id: circle.id, shopper_id });
            })
            .sort(s_by_identifier_postal_code)
            .map((circle) => 
                        <tr key={circle.id}>
                          <td>
                            <Link to={`/circles/${circle.id}/trips`}>
                              {circle.identifier}
                            </Link>
                          </td>
                          <td>
                            <HeadingBuilder
                              headingText    = {circle.postal_code}
                              // the Circle's postal code
                              tippyAlertText = {getTippyPostalCode({circle})}
                              tippyText      = {getTippyPostalCode({circle})}
                              // postal_code of '99999' triggers tippy text
                            />
                          </td>
                        </tr>
            );
            return shopper_admins_these_circles;
      }

      const circleColumnComment =
          t('trip.circleColumn.postalCodeTippyContent')
        + ' // '
        + t('circle.postal_code.purposeTippyContent')

      //const listOfAddTripLinks = trips_exist(trips) ? mkListOfAddTripLinks({}) : null;
      const listOfAddTripLinks = mkListOfAddTripLinks({});
      console.log('listOfAddTripLinks', listOfAddTripLinks);
      const listOfAddTripLinksHasLinks = doesArrayExist(listOfAddTripLinks);

      const thereAreTrips =  tripsList && tripsList.length > 0;
      console.log('tl is t/f', thereAreTrips ? 'there are trips' : 'no trips');
      console.log('tl?', tripsList);

      const shopper_is_admin_of_some_circle = is_shopper_admin_of_some_circle({
        cShoppers: circleShoppers, shopper_id: shopper.id
      });

      const onlyOneCircleId =
        tcircle_id
          ? tcircle_id
          : (Array.isArray(circles) && circles.length === 1)
            ? circles[0].id
            : null;
      // console.log('first circle id is |', onlyOneCircleId, '|')

      const onlyOneCircleIdentifier =
        onlyOneCircleId
          ? circles.find(
              c => c.id === onlyOneCircleId
            ).identifier
          : null;

      const onlyOneCircleIdAndIsAdmin = (
        onlyOneCircleId &&  is_shopper_a_circle_admin({
  cShoppers: circleShoppers, circle_id: onlyOneCircleId, shopper_id: shopper.id
        })
      ) ? onlyOneCircleId
        : null;

      const tripsHeadline =
        t('trip.formPart.headlineLabel') + ' ' + t('global.label.forCircle');

      const msgOnlyOneCircleAndNoTripsScheduled =
        t('trip.pickATrip.noTrips') + ' ' + t('global.label.forCircle');

      // Instead of a ternary invoking another ternary, this function tests
      // for various conditions and returns a one-element array once it finds
      // a match.
      // React wants array elements to have keys, so each condition returns a
      // Fragment with a key.
      const createTripButton = ({listOfAddTripLinksHasLinks, onlyOneCircleId}) => {
        const returnVal = [];

        // Show a button for making a new trip for a specific circle if we are
        // showing only one circle's trips and shopper is admin for that circle.
        if (onlyOneCircleIdAndIsAdmin) {
          returnVal.push(
            <React.Fragment key='10'>
              <HeadingBuilder
                Size           = 'h5'
                headingText    = {t('trip.circleAddTrip.label')}
                value          = {onlyOneCircleIdentifier}
                // Create a Trip for Circle oiyup
                linkUrl        = {`/circles/${onlyOneCircleId}/trips`}
                goButtonText   = {Constants.GET_ICON_RT_ARROW}
                
                tippyAlertText = {t('trip.circleAddTrip.directTippyContent')}
                tippyText      = {t('trip.circleAddTrip.directTippyContent')}
                // Click this button to bring up the 'Schedule a new Trip' panel
              />
            </React.Fragment>
          )
          return returnVal;
        }

        // There is a button for creating a new trip at the bottom of the list
        // of trips. If the list is long, the shopper may have to scroll a bit.
        // This is irritating if the shopper knows they want to create a trip,
        // so show a button at top if there are more than SMALL_NUM_OF_TRIPS.
        // No need for a button here if the list is short and button at the
        // bottom is already/almost visible.
        if (listOfAddTripLinksHasLinks &&
            tripsList  &&  tripsList.length > Constants.SMALL_NUM_OF_TRIPS) {
          returnVal.push(
            <React.Fragment key='20'>
              <HeadingBuilder
                Size           = 'h5'
                headingText    = {t('trip.circleAddTrip.scheduleNewTripLabel')}
                // Schedule a new Trip for a Circle
                hrefTo         = '#CreateATripForACircle'
                goButtonText   = {Constants.GET_ICON_RT_ARROW}
                tippyAlertText = {t('trip.circleAddTrip.hrefChooseCircleTippyContent')}
                tippyText      = {t('trip.circleAddTrip.hrefChooseCircleTippyContent')}
                // Click this button for the panel to choose a Circle and ...
              />
              <br/>
            </React.Fragment>
          )
          return returnVal;
        }

        return null;
      }

      return (
        <>
          <article>
                { thereAreTrips && onlyOneCircleId
                  ? <HeadingBuilder
                      Size        = 'h3'
                      headingText = { tripsHeadline }
                      value       = { onlyOneCircleIdentifier }
                      // Trips for Circle oipuy
                    />
                  : <HeadingBuilder
                      Size        = 'h3'
                      headingText = { t('trip.formPart.headlineLabel') }
                      // Trips
                    />
                }

                {createTripButton(
                  {listOfAddTripLinksHasLinks, onlyOneCircleId}
                )}

      {/* If there are trips, and shopper is an admin for one of them,
      then show the 'Trip Hidden?' and 'Remove Trip?' buttons. */}
                    { thereAreTrips &&
                      (
      // If shopper is here for a single circle, and is an admin of the circle
                        onlyOneCircleIdAndIsAdmin
                          ||
      // or viewing trips for all circles, and is an admin for one of them
                        (
                          !onlyOneCircleId &&  is_shopper_admin_of_some_circle({
                            cShoppers: circleShoppers, shopper_id: shopper.id
                          })
                        )
                      )
      // then do:
                      ? <>
                     		<Checkbox
                          isChecked={showTripDespiteHidden}
                          setIsChecked={toggleShowingHiddenTrips}
                        label={ t('trip.hideAndRevealHiddenTrips.labelReveal') }
                          // "Show 'Trip Hidden?' column"
                          tippyAlert = {t('trip.hideAndRevealHiddenTrips.tippyContent')}
                        />
                     		<Checkbox
                          isChecked={showRemoveTripColumn}
                          setIsChecked={toggleShowRemoveTripColumn}
                  label={ t('trip.toggleButtonToRemoveTrip.labelToShowColumn') }
                          // Show 'Remove Trip?' column
                          tippyAlert = {removeTripButtonTippyText}
                        />
                        <br/>
                        </>
                      : null
                    }

                    { thereAreTrips
                      ? <>
                        <HeadingBuilder
                          Size        = 'h5'
                          headingText = {t('trip.pickATrip.mainHeadline')}
                          // Click a Trip's Description to see its Shopping List
                        />
                        <Table striped bordered hover style={tableShow}>
                          {/* <thead> */}
                          <thead style={textAlignLeft}>
                            <tr>
                              <th>
                                <HeadingBuilder
                                  containerType  = 'container-hug-bottom-left'
                                  headingText    = {t('global.label.circle')}
                                  // Circle
                                  buttonAction   = {toggleShowZipCodes}
                                  tippyText      = {circleColumnComment}
                                  tippyAlertText = {circleColumnComment}
                                  // What Circle does this Trip belong to? // You can reveal (or hide) the postal code...
                                />
                              </th>
                              { showCircleZipCodes
                                  ? <th>
                                      { t('global.label.postalCode') }
                                      {/* Zip Code */}
                                    </th>
                                  : null
                              }
                              {/* <th>{ t('global.label.trip') }</th> */}
                              <th>{ t('trip.pickATrip.colHeaderTripDate') }</th>
                              {/* Trip Date */}
                              <th>
                                <HeadingBuilder
                                  containerType  = 'container-hug-bottom-left'
                                  headingText    = { t('trip.pickATrip.colHeaderTrips') }
                                  // Trip Description
                                  tippyAlertText = { t('trip.pickATrip.tippyContent') }
                                  tippyText      = { t('trip.pickATrip.tippyContent') }
                                  // Click a Description in this column to access the Trip and its Shopping List.
                                />
                              </th>
                              {/* Trip Description */}
                              <th>{
                                // Note: We won't be seeing a table if no trips.
                                shopper_is_admin_of_some_circle &&
                                  showTripDespiteHidden
            ? <HeadingBuilder
                headingText    = {t('trip.hideAndRevealHiddenTrips.columnHeader')}
                // Trip Hidden?
                tippyAlertText = {t('trip.hideAndRevealHiddenTrips.tippyContent')}
                tippyText      = {t('trip.hideAndRevealHiddenTrips.tippyContent')}
              />
            : null
                              }
                              </th>
                              <th>
                                { showRemoveTripColumn
                       ? <HeadingBuilder
                           headingText    = {t('trip.removeTrip.label')}
                           // Remove Trip
                           tippyAlertText = {t('trip.removeTrip.tippyContentColHead')}
                           tippyText      = {t('trip.removeTrip.tippyContentColHead')}
                         />
                       : null
                                }
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tripsList}
                          </tbody>
                        </Table>
                        </>

          // else there are no trips. Say so. If only one circle, mention it.
                      : onlyOneCircleId
                        ? <HeadingBuilder
                            headingText = {msgOnlyOneCircleAndNoTripsScheduled}
                            value       = {onlyOneCircleIdentifier}
                            // No Trips scheduled for Circle oipuy
                          />
                        : <HeadingBuilder
                            headingText = { t('trip.pickATrip.noTrips') }
                            // No Trips scheduled
                          />
                    }

          </article>
              <br/>
              { listOfAddTripLinksHasLinks
                  ? <article id='CreateATripForACircle'>
                      <hr />
                      <HeadingBuilder
                        Size           = 'h3'
                        headingText    = {t('trip.circleAddTrip.scheduleNewTripHeading')}
                        // Click a Circle to schedule a Trip
                        tippyAlertText = {t('trip.circleAddTrip.scheduleNewTripTippyContent')}
                        tippyText      = {t('trip.circleAddTrip.scheduleNewTripTippyContent')}
                        // Click a Circle below to create a new Trip for that Circle....
                      />
                      <Table responsive striped bordered hover size="sm" style={tableShowNarrow}>
                        <thead >
                        </thead>
                        <tbody style={textAlignLeft} >
                          { listOfAddTripLinks }
                        </tbody>
                      </Table>
                    </article> 
                  : null
              }
        </>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}


export default Trips;