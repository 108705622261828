import React from 'react';
import * as Constants from '../constants';
import { IoPause } from "react-icons/io5";
import { RxResume } from "react-icons/rx";
import { IoIosRewind } from "react-icons/io";
import GetIcon from '../components/GetIcon';

export const AudioPlayButtons = ({
  handlePlay,
  handlePause,
  handleStop,
  isPaused,
  playWasClicked,
}) => {

  return (
    <swa-nobr style={{whiteSpace: "nowrap",}}>
      <button onClick={handlePlay}>
        {isPaused
          ? <RxResume />
          : <GetIcon toGet={Constants.GET_ICON_AUDIO} />
        }
      </button>
      { playWasClicked
        ? <>
            <button onClick={handlePause}>
              <IoPause />
            </button>
            <button
              type="button"
              style={{
                border: "none",
                margin: 5,
                padding: 0,
                background: Constants.O_BOX_RED,
              }}
              onClick={handleStop}
            >
              <IoIosRewind />
            </button>
          </>
        : null
      }
    </swa-nobr>
  )
}

export default AudioPlayButtons;
