import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
//import { useParams, useLocation } from 'react-router-dom'
import { ShopperContext } from '../context/shopper';
import { Link } from 'react-router-dom';
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import * as Constants from '../constants';
import HeadingBuilder from '../components/HeadingBuilder';
import { BackButton } from '../components/BackButton';
import { TermsPrivacyUrls } from '../components/pieces/TermsPrivacyUrls';
import styles from '../css/trTdFirstChild.module.css';

// TODO: clean up "location" stuff.
// This is the page we will open from a list of CircleShoppers to view/edit shopper's info
const Shopper = () => {
  const { shopper, circleShoppers, signedIn} = useContext(ShopperContext);
  console.log('sjs shopper: ', shopper)

  //const location = useLocation();
  //console.log('shopper_loc_state', location.state);
  //const { shopper_name, admin_status, identifier, cshopper_id } = location.state;

  const { tableShow, tableData, visibilityCollapse } = useStyles();
  const { t } = useTranslation();

  const {
    getShowTippyHelpIconLabel,
    getShowTooltipsTippy,
    getShowTooltipsTippyAudio,
    getShowVideoTipsTippy,
    getShowVideoTipsTippyAudio,
    getShopperShortnameTippyText,
    getShopperShortnameTippyAudio,
  } = usePhrasing();

  const {id} = useParams();
  console.log('Shopper.js id: ', id)

  // TODO: This isn't working. Wonder why.
  // Don't see ill effect so I'm leaving active, but adding this only as a
  // comment in App.js:
  // <Route path="/shoppers" element={<Home />} />
  //
  // This logic from Trip.js (see comments there.)
  const navigate = useNavigate();
  //if (circleShoppers.length === 0)
  //if (shopper.length === 0)
  //if (shopper.id === undefined) 
  // https://bobbyhadz.com/blog/javascript-check-if-object-is-empty
  if (Object.keys(shopper).length == 0) {
    // This log message shows up:
    console.log('Shopper.js Im here: ')

    // Let's go back to the 'home' page, '/',
    // but 'navigate' has no effect, no matter where I direct it:
    // navigate('/')
    // window.location.replace('/');
  }

  const showTippyHelpIconLabel     = getShowTippyHelpIconLabel();

  const showTooltipsTippy          = getShowTooltipsTippy();
  const showTooltipsTippyAudio     = getShowTooltipsTippyAudio();

  const showVideoTipsTippy         = getShowVideoTipsTippy();
  const showVideoTipsTippyAudio    = getShowVideoTipsTippyAudio();

  const shopperShortnameTippyText  = getShopperShortnameTippyText();
  const shopperShortnameTippyAudio = getShopperShortnameTippyAudio();

  // Does 'id' belong to the shopper or a circleShopper? Else it's invalid.
  const shopperIsSelf =   shopper.id == id  ? shopper : null;

  const circleShopperIsOther =
    shopperIsSelf
      ? null
      : circleShoppers.find( (cs) =>  cs.shopper_id == id )
        // TODO: get warning for '==', but '===' fails - quiesce?

  const aShopper = shopperIsSelf ||
    (circleShopperIsOther  ? circleShopperIsOther.shopper : {});
  console.log('sjs aShopper: ', aShopper)


  const shopperProfileHeadineValue =
    aShopper.id
      ? aShopper.shortname
      : '(' + t('global.label.notFound') + ')';

  const otherShopperEmail =
    aShopper.id
      ? '(' + t('global.label.hiddenForNow') + ')'
      : "";

  if (signedIn) {

  return (
    <article>
      <HeadingBuilder
        Size           = 'h3'
        headingText    = {t('shopper.formPart.shopperProfileHeadlineLabel')}
        value          = {shopperProfileHeadineValue}
                       // Shopper Profile: newguy
      />
      <Table responsive striped bordered hover size="sm" style={tableShow}>
        <thead>
        </thead>
        <tbody className={styles.trTdFC} style={tableData} >
          <tr>
            <td>
              {/* Username */}
              { t('global.label.username') }
            </td>
            <td>
              {aShopper.identifier}
            </td>
          </tr>
          <tr>
            <td>
              {/* First name */}
              {t('shopper.firstname.label')}
            </td>
            <td>
              {aShopper.firstname}
            </td>
          </tr>
          <tr>
            <td>
              {/* Last name */}
              {t('shopper.lastname.label')}
            </td>
            <td>
              {aShopper.lastname}
            </td>
          </tr>
          <tr>
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('shopper.shortname.label')}
                // Short name
                tippyAlertText = {shopperShortnameTippyText}
                tippyText      = {shopperShortnameTippyText}
                tippyAlertAudio= {shopperShortnameTippyAudio}
              />
            </td>
            <td>
              {aShopper.shortname}
            </td>
          </tr>
          <tr>
            <td>
              {/* Email */}
              {t('shopper.email.label')}
            </td>
            <td>
  {/* {aShopper.email} - present this in a masked fashion? enable messaging? */}
              {
                shopper.id == aShopper.id
                  ? <span> {aShopper.email}</span>
                  : <span> {otherShopperEmail}</span>
              }
            </td>
          </tr>
          <tr>
            <td>
              {/* Zip Code */}
              {t('shopper.postal_code.label')}
            </td>
            <td>
              {aShopper.postal_code}
            </td>
          </tr>
          <tr
            // 'false' hides this row of the table.
            // If the shopper viewing the record (shopper.id) does not match
            // the id of the record being viewed here, then visibilityCollapse 
            style={shopper.id == id ? null : visibilityCollapse }
          >
            <td>
              {/* Settings: */}
              <em>{t('shopper.settings.label')}</em>
            </td>
            <td>
              {null}
            </td>
          </tr>
          <tr
            style={shopper.id == id ? null : visibilityCollapse }
          >
            <td>
              <HeadingBuilder
                containerType  = 'container-input-label'
                headingText    = {showTippyHelpIconLabel}
                // Show tooltips via [?] button
                tippyAlertText = {showTooltipsTippy}
                tippyText      = {showTooltipsTippy}
                tippyAlertAudio= {showTooltipsTippyAudio}
              />
            </td>
            <td>
              {aShopper.show_tippy_help_alert_icons  ? 'Yes' : 'No'}
            </td>
          </tr>
          <tr
            style={shopper.id == id ? null : visibilityCollapse }
          >
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('shopper.showVideoTipsIcon.label')}
                // Show video tips
                tippyAlertText = {showVideoTipsTippy}
                tippyAlertAudio= {showVideoTipsTippyAudio}
                tippyText      = {showVideoTipsTippy}
              />
            </td>
            <td>
              {aShopper.show_help_video_icons ? 'Yes' : 'No'}
            </td>
          </tr>
          <tr
            style={shopper.id == id ? null : visibilityCollapse }
          >
            <td>
              <HeadingBuilder
                containerType='container-input-label'
                headingText = {t('shopper.agreeTermsPrivacyA.label')}
                // I have read and agree to...
                tippyAlertText = {t('shopper.agreeTermsPrivacyA.termsPrivacy')}
                tippyText      = {t('shopper.agreeTermsPrivacyA.termsPrivacy')}
                // 'Terms' is the agreement between you and...
              />
            </td>
            <td>
              {aShopper.agree_tos_pp_a ? 'Yes' : 'No'}
              &nbsp; - &nbsp;
              <TermsPrivacyUrls />
            </td>
          </tr>
        </tbody>
      </Table>
      {
        shopper.id == aShopper.id  &&
        <>
          <HeadingBuilder
            tippyAlertText = {t('shopper.changeProfileButton.tippyContent')}
            tippyText      = {t('shopper.changeProfileButton.tippyContent')}
            linkUrl        = {`/shoppers/${shopper.id}/edit`}
            goButtonText   = {Constants.GET_ICON_RT_ARROW}
            headingText    = {t('shopper.changeProfileButton.label')}
                           // Change Profile Details:
            value          = {aShopper.identifier}
          />
          <br/>
          <HeadingBuilder
            tippyAlertText = {t('shopper.deleteAccountButton.tippyContent')}
            tippyText      = {t('shopper.deleteAccountButton.tippyContent')}
            linkUrl        = {`/shoppers/${shopper.id}/delete`}
            headingText    = {t('shopper.deleteAccountButton.label')}
                           // Delete My Account
          />
        </>
      }
      <BackButton />
      {/* <BackButton whatToDo={resetSkuTypeFormFlags}/> */}
    </article>
  )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}
export default Shopper