import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { useUtils } from "../hooks/useUtils";
import { useParams, useNavigate } from 'react-router-dom';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import { useTranslation } from "react-i18next";
import * as Constants from '../constants';

export const TripDeleteForm = () => {
    const { signedIn, shopper, circleShoppers,
            trips, deleteTrip } = useContext(ShopperContext);
    console.log('tdf circleShoppers: ', circleShoppers)

    const navigate = useNavigate();

    const { is_shopper_a_circle_admin } = useUtils();
    const { toMmmDdYyyy, toHmmA } = useUtils();

    const {id} = useParams();
    console.log('tdf id: ', id)

    const { t } = useTranslation();

    const tripObj = trips.find(t => t.id == id);
    console.log('tdf tripObj: ', tripObj)

    if (tripObj == null) {
      console.log('tdf tripObj2: ', tripObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'tripObj' to be 'undefined'.
      // Let's go back to the 'trips' page.
      window.location.replace('/trips/');
    }

    async function removeTrip (e) {
      // TODO: is event (e) useful for anything?
  
      // Delete a circle.
      // Backend only allows circle's admins to delete a circle.
  
      console.log("tdf before di", Date.now() );
      const rc = await deleteTrip(tripObj.id);
      console.log("tdf rc", rc);
      console.log("tdf after dt", Date.now() );
  
      // Rails should have deleted the trip's order_items
      // TODO: Need some error handling here?

      // The trip is gone - don't reference in localStorage as 'LAST_TRIP_SEEN'.
      const lastTripSeenForShopperKey =
                                    Constants.LAST_TRIP_SEEN_FOR + shopper.id;
      const data = window.localStorage.getItem(lastTripSeenForShopperKey);
      if (data) {
        const lastTripSeenId = JSON.parse(data);
        if (lastTripSeenId === tripObj.id) {
    window.localStorage.setItem(lastTripSeenForShopperKey, JSON.stringify(''));
        }
      }

      // Now that we have deleted a trip, e.g. id 51, this url has no trip
      // to delete:
      // http://localhost:4000/trips/51/delete
      // We see error messages complaining about not finding that id.
      // Instead of letting that happen, let's navigate to safer ground:
      navigate('/trips/', {replace: true});

      // Refresh to update state, make sure future activity doesn't present
      // the deleted trip.
      navigate(0);
      // The effect is clumsy. It takes a long time and its not intuitive why
      // we did not land back on the '/trips/' page.

      // I would have thought this line would cause the browser to start
      // showing '/trips/' but the view remains fixed at '/'.
      // And yet without this line, we see an ugly error upon clicking a Back
      // button. (We have just deleted a trip so that going to a url for a
      // nonexistent trip fails.)
      // Helpfully, if shopper clicks Back while now viewing '/', this line 
      // causes the '/trips/' view to display.
      navigate('/trips/', {replace: true});
    }

    const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
      cShoppers: circleShoppers, circle_id: tripObj.circle.id, shopper_id: shopper.id
    });

    const removeTripHeadComment =
        t('trip.removeTrip.tippyContentAYSHead')
      + ' // '
      + t('trip.removeTrip.tippyContentB')

    const removeTripButtonComment =
        t('trip.removeTrip.tippyContentAYSButton')
      + ' // '
      + t('trip.removeTrip.tippyContentB')

    if (signedIn && if_shopper_is_circle_admin) {
      return (
        <article>
          <HeadingBuilder
            Size        = 'h3'
            headingText = {t('trip.formPart.headlineDeleteTrip')}
            value       = {tripObj.label}
            // Delete Trip: Publix
          />
          { toMmmDdYyyy({dateString: tripObj.day, weekday: 'short'})
            }, { toHmmA({timeString: tripObj.hour}) }
          <br/>
          <br/>
          <HeadingBuilder
            Size = 'h1'
            headingText    = {t('global.areYouSure')}
            // Are You Sure?
            tippyAlertText = {removeTripHeadComment}
            tippyText      = {removeTripHeadComment}
          />
          <HeadingBuilder
            buttonAction   = {removeTrip}
            headingText    = {t('trip.removeTrip.label')}
            // Remove Trip
            tippyAlertText = {removeTripButtonComment}
            tippyText      = {removeTripButtonComment}
          />
          <br/>
          <BackButton />
        </article>
      )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default TripDeleteForm;