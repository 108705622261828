import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import InvitationForm from '../components/InvitationForm'
import CircleShopperRow from '../components/CircleShopperRow'
import { ErrorList } from '../components/ErrorList';
import Checkbox from '../components/Checkbox';
import HeadingBuilder from '../components/HeadingBuilder';
import * as Constants from '../constants';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
              
const CircleShoppers = ({circle_id, circle_identifier, circle_leader_id, open_invites, accepted_invites}) => {
  console.log('c_id_prop', circle_id);
  console.log('c_identifier_prop', circle_identifier);
  console.log('c_invites', open_invites);
  console.log('c_accepted_invites', accepted_invites);

  const { shopper, signedIn, errorsDoExist, circles, circleShoppers } = useContext(ShopperContext);
  console.log('cs_circleShoppers', circleShoppers);  // TODO: this will soon dump too much data...

  const [invitationFormFlag, setInvitationFormFlag] = useState(false);
  const lightUpAddInvitationButton = () => {
      setInvitationFormFlag(false);
  }

  const [showRemoveShopperColumn, setShowRemoveShopperColumn] = useState(false);
  const toggleShowRemoveShopperColumn = () => {
      setShowRemoveShopperColumn(() => !showRemoveShopperColumn);
      console.log("srsc", showRemoveShopperColumn);
  };

  const [justMadeInvite, setJustMadeInvite] = useState(false);
  const [invitee, setInvitee] = useState('');
  const passInvitee = (inviteeFromForm) => {
    setInvitee(inviteeFromForm);
  };

  const { tableShow, textAlignLeft } = useStyles();
  const { is_shopper_a_circle_admin } = useUtils();
  const { t } = useTranslation();

  if (signedIn) {
      // Is current_shopper an admin for the current circle?
      // TODO: Hmm, reaching outside of function for value of 'circle_id' and 'shopper'
      const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
        cShoppers: circleShoppers, circle_id: circle_id, shopper_id: shopper.id
      });
      console.log('cs isica if_shopper_is_circle_admin', if_shopper_is_circle_admin);

      const if_shopper_is_circle_leader =  shopper.id === circle_leader_id;

      const labelToStartShowing =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.toggleButtonPermitsLabel')
          : t('circleShopper.leaveCircle.toggleButtonPermitsLabel');

      const tippyTextForStartShowing =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.qRemoveShopperFromCircleTippyText') +
            t('global.clickCheckboxToShowHideColumnTippyText')
          : t('circleShopper.leaveCircle.toggleButtonPermitsTippyText') +
            t('global.clickCheckboxToShowHideColumnTippyText') +
            t('circleShopper.leaveCircle.toggleButtonWarningTippyText') +
            t('global.qWantToProceedTippyText');

      const labelToStopShowing =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.toggleButtonDisablesLabel')
          : t('circleShopper.leaveCircle.toggleButtonDisablesLabel');

      const tippyTextForStopShowing =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.toggleButtonDisablesTippyText')
          : t('circleShopper.leaveCircle.toggleButtonDisablesTippyText');

      const tippyTextForStartOrStopShowing =
  showRemoveShopperColumn ? tippyTextForStopShowing : tippyTextForStartShowing;

      const removeShopperColumnHeading =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.columnLabel')
          : t('circleShopper.leaveCircle.columnLabel');

      const removeShopperColumnTippyContent =
        if_shopper_is_circle_leader
          ? t('circleShopper.removeShopper.qRemoveShopperFromCircleTippyText') +
            t('global.removeWarning') +
            t('circleShopper.removeShopper.columnHeaderTippyContent') +
            t('circleShopper.removeShopper.tippyContent2')
          : t('circleShopper.leaveCircle.tippyContent1') +
            t('global.removeWarning') +
            t('circleShopper.leaveCircle.columnHeaderTippyContent') +
            t('circleShopper.leaveCircle.tippyContent2');

      console.log('isicl if_shopper_is_circle_leader', if_shopper_is_circle_leader);
      console.log('c_id', circle_id);
      const circleShoppersList =
        circleShoppers.filter(cs => cs.circle_id==circle_id)
                      .map(cs => <CircleShopperRow
                             key={cs.id}
                             cs={cs}
                            //  if_shopper_is_circle_admin={if_shopper_is_circle_admin}
                             if_shopper_is_circle_leader={if_shopper_is_circle_leader}
                            //  admin_id={shopper.id}
                             cShopper={JSON.stringify(cs.shopper)}
                             circle_id={cs.circle_id}
                             circle_identifier={circle_identifier}
                             circle_leader_id={circle_leader_id}
                             invitation={accepted_invites.find(i => i.invitee == cs.shopper.identifier) || open_invites.find(i => i.invitee == cs.shopper.identifier)}
                             showRemoveShopperColumn={showRemoveShopperColumn}
                          />
                      )
      // Invitation needs two finds: the invitation likely has status 'Accepted',
      // but maybe shopper changed the status to 'Rejected' or 'Improper' after
      // they accepted.
      // Hmm, as of 231123 (and earlier), status of 'Accepted' can't be changed.
      console.log('CShoppersList', circleShoppersList);

      const successTellInviteeMsg = 
          t('circleShopper.inviteShopperButton.warningForSubmit10')
          // You have invited '
        + invitee
          // alice
        + t('circleShopper.inviteShopperButton.warningForSubmit30');
          // ' to join your circle! Contact them and tell them you have created an invitation.

      return (
          <div>
              <HeadingBuilder
                Size = 'h3'
                headingText = {t('circleShopper.formPart.headlineLabel')}
                // Circle Shoppers
                tippyAlertText = {t('circleShopper.formPart.headlineTippyContent')}
                tippyText = {t('circleShopper.formPart.headlineTippyContent')}
              />
              <HeadingBuilder
                Size = 'h5'
                headingText = {t('circleShopper.formPart.in')}
                // in
                value = {circle_identifier}
              />
              <Table striped bordered hover style={tableShow}>
                <thead style={textAlignLeft}>
                  <tr>
                    {/* Shortname / Name / Admin */}
                    <th>{t('shopper.shortname.label')}</th>
                    <th>{t('circleShopper.formPart.nameLabel')}</th>
                    <th>{t('circleShopper.formPart.adminLabel')}</th>

                    <th>
                      { showRemoveShopperColumn
                        ? <HeadingBuilder
                            containerType  = "container-left"
                            headingText    = {removeShopperColumnHeading}
                            // Leave Circle? | Remove Shopper?
                            tippyAlertText = {removeShopperColumnTippyContent}
                            tippyText      = {removeShopperColumnTippyContent}
                          />
                        : null
                      }
                    </th>
                  </tr>
                </thead>
                <tbody style={textAlignLeft}>
                  {circleShoppersList}
                </tbody>
              </Table>
              { errorsDoExist && <ErrorList /> }
              {if_shopper_is_circle_admin && <br/>}
              {invitationFormFlag
                ? <InvitationForm
                  circle_id={circle_id}
                  circle_identifier={circle_identifier}
                  setJustMadeInvite={setJustMadeInvite}
                  passInvitee={passInvitee}
                  lightUpAddInvitationButton={lightUpAddInvitationButton} />
                : (if_shopper_is_circle_admin
                    ? <HeadingBuilder
                        headingText    = {t('circleShopper.inviteShopperButton.label')}
                        // Invite a Shopper to the Circle (requires admin)
                        buttonAction   = {() => setInvitationFormFlag(true)}
                        goButtonText   = {Constants.GET_ICON_RT_ARROW}
            
                        tippyAlertText = {t('circleShopper.inviteShopperButton.tippyContent')}
                        tippyText      = {t('circleShopper.inviteShopperButton.tippyContent')}

                        forceShowBWarning = {justMadeInvite}
                        setShowBWarning   = {setJustMadeInvite}
                        bWarningMsg       = {successTellInviteeMsg}
                        // You have invited 'alice' to join your circle! Contact them and tell them you have created an invitation.
                      />
                    : null
                  )
              }
              <br/>
              { circleShoppersList.length > 1
                // Don't show button if Leader is only one in the list.
                // Can't remove self from circle if self is Leader, and
                // Leader is guaranteed to be in the list.
                // So, need others to be in the list to show the button.
              ? <Checkbox
                  isChecked={showRemoveShopperColumn}
                  setIsChecked={toggleShowRemoveShopperColumn}
                  label={showRemoveShopperColumn
                    ? labelToStopShowing
                    // Show ['Remove a Shopper'|'Leave the Circle'] column?
                    : labelToStartShowing
                    // Leave the Circle | Remove a Shopper
                  }
                  tippyAlert={tippyTextForStartOrStopShowing}
                />
              : null
              }
              <br/>
          </div>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default CircleShoppers;
