import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ShopperContext } from '../context/shopper';
import { usePhrasing } from "../hooks/useSkuUtils";
import { useUtils } from "../hooks/useUtils";
import { findAbsAmtAsPct } from '../utils';
export const SkuMenuLink = ( {sku, trip, incrementPlus, decrementPlus } ) => {
  const { shopper, circleShoppers, orderItems, addOrderItem,
    dismissErrorsList, setErrorsDoExistToFalse, setJustGotClearToFalse,
  } = useContext(ShopperContext);
  //console.log("spl shopper", shopper)
  console.log("orderItemsSPL do_me", orderItems)
  //console.log("spl sku", sku)
  //console.log("spl trip", trip)

  // initialize with first pattern element we show, hardcoded here:
  const [pattern, setPattern] = useState('*__');
  const [addOiInProgress, setAddOiInProgress] = useState(false);
  const [count, setCount] = useState(0);

  const { weightAndUnitsOfSku } = usePhrasing();
  const { shoppers_order_for_this_sku_this_trip, getSpinString } = useUtils();
  const { t } = useTranslation();

  // With each SkuMenuLink we see if the shopper already has a matching
  // orderItem for a given sku of the circle for this trip.
  //console.log('skulink circles', circles)
  const matchingOrder = shoppers_order_for_this_sku_this_trip({
    oItems: orderItems,
    shopper_id: shopper.id,
    sku_id: sku.id,
    trip_id: trip.id
  })
  console.log('matchingOrderM do_me', shopper.id, sku.id, trip.id, matchingOrder)

  useEffect(() => {
    if (matchingOrder === undefined) {
      incrementPlus();
    }
  }, []);
  // Only run this once, i.e. don't change the dependency array without reason.
  // Together with any other 'SkuMenuLink' components that do 'incrementPlus',
  // we'll know how many plus signs were in effect upon first showing the
  // Maybe-List. We'll decrement this count upon each click of a '+'. When it
  // hits zero, we'll stop showing the message advising to "Click the '+' sign."

  const timerIdRef = useRef(null);
  
  useEffect(() => {
    if(addOiInProgress){
      timerIdRef.current = setTimeout(() => {
        setPattern( getSpinString({place: count}) );
        setCount((prevCount) => prevCount + 1);
      }, 300);
      // It doesn't matter that we bump 'count' afterwards.
      // It changes, so the pattern that we show will look different next time.
    }
    
    // Not comfortable with this. Who/what is listening for a 'return'?
    // I've copied this code from
// https://learnersbucket.com/examples/interview/increment-counter-component-in-react/
    // and the sample code did not include 'return'.
    // But vscode shows errors. And, including 'return' seems to fix them.

    return () => {
      clearTimeout(timerIdRef.current);
    }
  }, [count, addOiInProgress, getSpinString])

  // TODO: If this is needed, why not do it earlier?
  if (sku) {
  console.log('skulink skuXY', sku)
  } else {
    return
  }

  // The shopper has clicked the '+' button to add the sku to the circle's
  // shopping list since the orderItems state variable in Context had no match
  // for the shopper, sku and trip. We will add orderItem records with
  // abs_amount set to zero for this sku for each shopper in the circle.
  const handleClick = ({sku, trip}) => {
    console.log("spl hc sku", sku);
    console.log("spl hc trip", trip);
    const initialAbsAmt = 0;

    // decrement the count of 'plus' signs by one:
    decrementPlus();

    // clear out any error messages, success statuses
    dismissErrorsList();
    setErrorsDoExistToFalse();
    setJustGotClearToFalse();

    circleShoppers.filter(cs => cs.circle_id == trip.circle.id ).forEach(cs => {
        const orderItem = {
          shopper_id: cs.shopper_id,
          // the Rails 'create' action should verify that cs.shopper_id is in this
          // current_shopper's circle

          sku_id: sku.id,
          trip_id: trip.id,
          abs_amount: initialAbsAmt
        };
        console.log("orderItemP", orderItem)
        setAddOiInProgress(true);
        (async function () {
          try {
          await addOrderItem(orderItem);
          clearTimeout(timerIdRef.current);
          setAddOiInProgress(false);
          } catch (error) {
            clearTimeout(timerIdRef.current);
            setAddOiInProgress(false);
            console.error(error);
          }
        })();
        console.log("orderItemsY", orderItems)
    })
  }

  // checkbox advice per https://www.robinwieruch.de/react-checkbox/
  // button advice per https://www.robinwieruch.de/react-button/
  // https://upmostly.com/tutorials/pass-a-parameter-through-onclick-in-react
  return (
    <tr>
      <td>
        { matchingOrder === undefined
            ? <button type="button" onClick={() => handleClick({sku,trip})}>
                { addOiInProgress ? pattern : '+' }
              </button>
            : (
              matchingOrder.sku.type == 'WeightyItem'
                ? Math.trunc( matchingOrder.abs_amount * 100
                    / (matchingOrder.sku.typical_weight || 1) ).toString() + "%"
                : matchingOrder.sku.type == 'DiscreteItem'
                  ? Math.trunc( 
                      findAbsAmtAsPct({
                        topNum: matchingOrder.abs_amount,
                        sku: matchingOrder.sku
                      })
                              ).toString() + "%"
                  : Math.trunc( matchingOrder.abs_amount * 100).toString() + "%"
              )
        }
      </td>

      <td>
        {sku.brand}
      </td>

      <td>
        <Link to={`/skus/${sku.id}`}>
          {sku.label}
        </Link>
        {
          weightAndUnitsOfSku({
            sku,
            showWeightAsSkuEditLink: false,
            showUnitCount: true
          })
        }
      </td>

      <td>
        {sku.store_label}
      </td>

    </tr>
  )
}
      // {/* FIX ME: And have a link to the associated Order-Item table? */}

export default SkuMenuLink
