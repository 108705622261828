import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { ShopperContext } from '../context/shopper'
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import { HeadingBuilder } from "../components/HeadingBuilder";
import Checkbox from "../components/Checkbox";
import { useTranslation } from "react-i18next";

export const GoShopping = ({
    trip,
    handleGoShopClick,
    goShopList,
    showOrderBreakout,
    handleOrderBreakoutClick,
  }) => {

  const { signedIn } = useContext(ShopperContext);
  const { tableShow, textAlignLeft } = useStyles();
  const { toMmmDdYyyy, toHmmA } = useUtils();
  const { t } = useTranslation();

  const orderBreakoutButtonTippyContent =
    showOrderBreakout
      ? t('trip.orderBreakoutButton.tippyContentWhenShowing')
        // Click this button to hide the Order breakouts.
      : t('trip.orderBreakoutButton.tippyContentWhenHiding');
        // Click this button for a breakout of what each Shopper ordered. // Clicking a Sku's label lets you change a Sku's details.

  if (signedIn) {
    console.log('GoShopping.js goShopList: ', goShopList);

    return (
      <article>
        <hr/>
        <h4>
          {t('trip.goShopping.headlineLabel')}
          {/* 'Go Shopping' List */}
        </h4>
        <b>{t('global.label.trip')+':'}</b>
          &nbsp;{ toMmmDdYyyy({dateString: trip.day, weekday: 'short'}) },
          &nbsp;{ toHmmA({timeString: trip.hour}) },
          &nbsp;{ trip.label }
          {/* Trip: Oct 8, 2022,  2:00 PM,  Costco, Publix */}
        <br />
        <b>{t('global.label.circle')+':'}</b> {trip.circle.identifier}
          {/* Circle: am_circle */}
        <br />
        <button onClick={handleGoShopClick}>
          {t('trip.goShopping.hideListLabel')}
          {/* Hide this 'Go Shopping' List */}
        </button>
        <br/>
        <Checkbox
          isChecked    = {showOrderBreakout}
          setIsChecked = {handleOrderBreakoutClick}
          label        = {t('trip.orderBreakoutButton.textToShow')}
          // Show who ordered what
          tippyAlert   = {orderBreakoutButtonTippyContent}
        />
        { goShopList
        ? <Table striped bordered hover size="sm" style={tableShow}>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody style={textAlignLeft}>
            {goShopList.flat(1)}
          </tbody>
          </Table>
        : <p>{t('ordersList.pageText.noOrderItemsDefined')}</p>
        }

        <button onClick={handleGoShopClick}>
          {t('trip.goShopping.hideListLabel')}
          {/* Hide this 'Go Shopping' List */}
        </button>
        <br/>
        <Checkbox
          isChecked    = {showOrderBreakout}
          setIsChecked = {handleOrderBreakoutClick}
          label        = {t('trip.orderBreakoutButton.textToShow')}
          // Show who ordered what
          tippyAlert   = {orderBreakoutButtonTippyContent}
        />
        <hr/>
      </article>
    )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default GoShopping;
