import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import SkuForm from './SkuForm';
import InAHurry from '../components/InAHurry';
import HeadingBuilder from '../components/HeadingBuilder';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import skuDiscreteSrc from "../assets/pic_sku_discreteb.jpeg";
import skuGranularSrc from "../assets/pic_sku_granularb.jpeg";
import skuByWeightSrc from "../assets/pic_sku_by_weightb.jpeg";

const SkuPic = styled.img`
    width: 120px;
    height: 120px;
    margin: 10px;
`;

const SkuChooseType = () => {
  const { signedIn, circles, foundSkus,
        } = useContext(ShopperContext);
  const [byWeightFormFlag, setByWeightFormFlag] = useState(false);
  const [discreteFormFlag, setDiscreteFormFlag] = useState(false);
  const [granularFormFlag, setGranularFormFlag] = useState(false);
  const [haveNotHandledUuidYet, setHaveNotHandledUuidYet] = useState(true);
  const [focusSkuType, setFocusSkuType] = useState('');

  const {circle_id, chosenUuid} = useParams();
  console.log('SkuChooseType.js circle_id: ', circle_id)
  console.log('SkuChooseType.js chosenUuid: ', chosenUuid)
  const navigate = useNavigate();

  const searchRef      = useRef(null);
  const discreteSkuRef = useRef(null);
  const granularSkuRef = useRef(null);
  const weightySkuRef  = useRef(null);

  useEffect(() => {
    if (signedIn) {
      if (chosenUuid) {
        switch (focusSkuType) {
          case 'DiscreteItem':
            discreteSkuRef.current.focus();
            break;
          case 'GranularItem':
            granularSkuRef.current.focus();
            break;
          case 'WeightyItem':
            weightySkuRef.current.focus();
            break;
          default:
            searchRef.current.focus();
        }
      }
      else {
        searchRef.current.focus();
      }
    }
  }, [signedIn, chosenUuid, focusSkuType]);

  const resetSkuTypeFormFlags = () => {
      setByWeightFormFlag(false);
      setDiscreteFormFlag(false);
      setGranularFormFlag(false);
  }

  const handleDiscreteClick = () => {
    resetSkuTypeFormFlags();
    setDiscreteFormFlag(true);
  }

  const handleGranularClick = () => {
    resetSkuTypeFormFlags();
    setGranularFormFlag(true);
  }

  const handleByWeightClick = () => {
    resetSkuTypeFormFlags();
    setByWeightFormFlag(true);
  }

  const { t } = useTranslation();

  const get_sku_type_callback = ({ searchSkuType }) => {
    switch (searchSkuType) {
      case 'DiscreteItem':
        return handleDiscreteClick;
      case 'GranularItem':
        return handleGranularClick;
      case 'WeightyItem':
        return handleByWeightClick;
      default:
        return;
    }
  }

 if (signedIn) {
  const circle = circles.find(c => c.id == circle_id);
  console.log('SkuChooseType.js circle: ', circle)
  if (circle == null) {
    console.log('sct circle2: ', circle)
    // User likely used browser to refresh the page, lost access to state.
    // This causes 'circle' to be 'undefined'.
    // Let's go back to the 'skus' page.
    window.location.replace('/skus/');
  }


  console.log('SkuChooseType.js haveNotHandledUuidYet', haveNotHandledUuidYet);
  if (chosenUuid && haveNotHandledUuidYet) {
    // use the chosenUuid to locate the sku to copy
    const search_sku = foundSkus.find(s =>  s.uuuid == chosenUuid);
    console.log('SkuChooseType.js search_sku: ', search_sku)

    if (!search_sku) {
      console.log('SkuChooseType.js 2 search_sku: ', search_sku)
      // Maybe shopper added a new sku for a trip. To return to the trip they
      // clicked the Back button, but it's returning to a filled-in Sku form.
      // Its 'chosenUuid' value no longer exists, e.g. we are heading here:
// circles/018e7c50-223e-7a51-8340-0b137d4a4517/skus/da2bbe4f-f6ef-4a0f-a1bf-148697f00722
      // Grr, now we are stuck viewing a blank screen. Let's get out of here!
      navigate('/', {replace: true});

      // Refresh to update state, make sure future activity doesn't take us to
      // the processed chosenUuid:
      navigate(0);
  
      // I would have thought this line would cause the browser to start
      // showing '/trips/' but the view remains fixed at '/'.
      // And yet without this line, we see an ugly error upon clicking a Back
      // button. (We have just processed a Sku so that going to a url for
      // a nonexistent 'choenUuid' fails.)
      // Helpfully, if shopper clicks Back while now viewing '/', this line (?)
      // causes the '/trips/' view to display.
      navigate('/trips/', {replace: true});
    }

    // set the appropriate type of SkuForm based on search_sku's type
    const handleClickTypeCallback = get_sku_type_callback({ searchSkuType: search_sku.type });
    // now run the callback, e.g. handleDiscreteClick()
    handleClickTypeCallback();
    setFocusSkuType(search_sku.type);

    setHaveNotHandledUuidYet(false);
  }

  const searchOtherCirclesTippyContent =
      t('sku.searchOtherCirclesForSku.tippyContent')
    + t('sku.searchOtherCirclesForSku.tippyContentClickLink');
  // Maybe another Shopping Circle has created and shared information about a
  // Sku you want to set up. Click here to search for and copy their Sku.

  return (
      <article>
        <div ref={searchRef} tabIndex="0" />
        <HeadingBuilder
          Size        = 'h3'
          headingText = {t('sku.searchOtherCirclesForSku.headingText')}
          value       = {circle.identifier}
                      // Add Sku for Circle: uipuy
          tippyAlertText = {t('sku.circleAddSku.addSkuForCircleTippyContentAlert')}
          tippyText      = {t('sku.circleAddSku.addSkuForCircleTippyContentAlert')}
          tippyInfoText  = {t('sku.circleAddSku.addSkuForCircleTippyContentInfo')}
        />

        <HeadingBuilder
          tippyAlertText = {searchOtherCirclesTippyContent}
          tippyText      = {searchOtherCirclesTippyContent}
          linkUrl        = {`/circles/${circle_id}/search_skus`}
          goButtonText   = {Constants.GET_ICON_RT_ARROW}
          headingText    = {t('sku.searchOtherCirclesForSku.label')}
                         // Search Other Circles for Similar Sku
        />
        <br/>
        <HeadingBuilder
          Size = 'h4'
          headingText = {t('sku.skuChooseType.whatKindOfItem')}
          // What kind of item is this? (Click the best match below.)
        />
        <HeadingBuilder
          Size = 'h4'
          headingText = {t('sku.skuChooseType.howToDivide')}
          // When Shoppers share in a purchase, how will they divide the item?
        />

       <div className="wrapSkuChooseType">
        <div ref={discreteSkuRef} tabIndex="0" >
          <SkuPic src={skuDiscreteSrc}
                  onClick={() => handleDiscreteClick()}
          />
        </div>
        <div>
          <HeadingBuilder
            // FIX ME: should be able to have/create a class that conveys 'bold':
            // containerType = 'text-bold' or 'fw-bold'
            // And then 'Size' can be set to 'h3' as expected.
            // Seems this way of 'h3' works via Tailwind.
            containerType = 'h3'
            Size = 'strong'
            headingText = {t('sku.discrete_instructions.title')}
            // Discrete
          />
          <HeadingBuilder
            headingText  = {t('sku.discrete_instructions.copy')}
            paraPlayText = {t('sku.discrete_instructions.copy')}
            // Shoppers will enter how many sub-units they want...
          />
          <br/>
          <InAHurry showInAHurryButton={discreteFormFlag}/>
          {discreteFormFlag
            ? <SkuForm
                                circle_id={circle_id}
                                skuType={'DiscreteItem'}
                                resetSkuTypeFormFlags={resetSkuTypeFormFlags}
                                chosenUuid={chosenUuid}
                              />
            : <HeadingBuilder
                buttonAction   = {() => handleDiscreteClick()}
                headingText    = {t('sku.discrete_instructions.buttonLabel')}
                // Sku is Discrete
                tippyAlertText = {t('sku.discrete_instructions.buttonTippyText')}
                tippyText      = {t('sku.discrete_instructions.buttonTippyText')}
              />
          }
          <hr/>
        </div>

        <div ref={granularSkuRef} tabIndex="0" >
          <SkuPic src={skuGranularSrc}
                  onClick={() => handleGranularClick()}
          />
        </div>
        <div>
          <HeadingBuilder
            // FIX ME: see above re 'bold'
            containerType = 'h3'
            Size = 'strong'
            headingText = {t('sku.granular_instructions.title')}
            // Granular
          />
          <HeadingBuilder
            headingText  = {t('sku.granular_instructions.copy')}
            paraPlayText = {t('sku.granular_instructions.copy')}
            // Shoppers will indicate a percentage showing...
          />
          <br/>
          <InAHurry showInAHurryButton={granularFormFlag}/>
          {granularFormFlag
            ? <SkuForm
                                circle_id={circle_id}
                                skuType={'GranularItem'}
                                resetSkuTypeFormFlags={resetSkuTypeFormFlags}
                                chosenUuid={chosenUuid}
                              />
            : <HeadingBuilder
                buttonAction   = {() => handleGranularClick()}
                headingText    = {t('sku.granular_instructions.buttonLabel')}
                // Sku is Granular
                tippyAlertText = {t('sku.granular_instructions.buttonTippyText')}
                tippyText      = {t('sku.granular_instructions.buttonTippyText')}
              />
          }
          <hr/>
        </div>

        <div ref={weightySkuRef} tabIndex="0" >
          <SkuPic src={skuByWeightSrc}
                  onClick={() => handleByWeightClick()}
          />
        </div>
        <div>
          <HeadingBuilder
            // FIX ME: see above re 'bold'
            containerType = 'h3'
            Size = 'strong'
            headingText = {t('sku.weight_instructions.title')}
            // By Weight
          />
          <HeadingBuilder
            headingText  = {t('sku.weight_instructions.copy')}
            paraPlayText = {t('sku.weight_instructions.copy')}
            // For an item that is sold by weight...
          />
          <br/>
          <InAHurry showInAHurryButton={byWeightFormFlag}/>
          {byWeightFormFlag
            ? <SkuForm
                                circle_id={circle_id}
                                skuType={'WeightyItem'}
                                resetSkuTypeFormFlags={resetSkuTypeFormFlags}
                                chosenUuid={chosenUuid}
                              />
            : <HeadingBuilder
                buttonAction   = {() => handleByWeightClick()}
                headingText    = {t('sku.weight_instructions.buttonLabel')}
                // Sku is By Weight
                tippyAlertText = {t('sku.weight_instructions.buttonTippyText')}
                tippyText      = {t('sku.weight_instructions.buttonTippyText')}
              />
          }
        </div>
       </div>
      </article>
  )
 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default SkuChooseType;