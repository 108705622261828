import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { ErrorList } from './ErrorList';
import { RadioButton } from './RadioButton';
import { TermsPrivacyUrls } from './pieces/TermsPrivacyUrls';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import { useTranslation } from "react-i18next";
//import styled from 'styled-components';
import styles from '../css/trTdFirstChild.module.css';

export const ShopperEditForm = () => {
    const { signedIn, shopper, circleShoppers, editShopper } = useContext(ShopperContext);
    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { setJustGotClearToFalse } = useContext(ShopperContext);
    console.log('sef shopper: ', shopper)
    console.log('sef circleShoppers: ', circleShoppers)

    const { tableShow, tableData, visibilityCollapse } = useStyles();
    const {
      getShowTippyHelpIconLabel,
      getShowTooltipsTippy,
      getShowTooltipsTippyAudio,
      getShowVideoTipsTippy,
      getShowVideoTipsTippyAudio,
      getShopperShortnameTippyText,
      getShopperShortnameTippyAudio,
    } = usePhrasing();

    const {id} = useParams();
    console.log('sef id: ', id)

    const shopperObj =
      shopper.id == id
        ? {shopper: shopper}
        : circleShoppers.find(cs =>  cs.shopper_id == id);
    console.log('sef shopperObj: ', shopperObj)

    if (shopperObj == null) {
      console.log('cef shopperObj2: ', shopperObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'shopperObj' to be 'undefined'.
      // Let's go back to the 'home' page, '/'.
      window.location.replace('/');
      // Not to '/shoppers/' because we don't use that to show shoppers.
      // This doesn't work either: window.location.replace(`/shoppers/${id}`);

      // Error messages still fly from using 'shopperObj', but at least this
      // lands somewhere nice.
    }

    const { t } = useTranslation();

    const initialState = {...shopperObj.shopper,
      show_tippy_help_alert_icons:
                       shopperObj.shopper.show_tippy_help_alert_icons ? 1 : 0,
      show_help_video_icons: shopperObj.shopper.show_help_video_icons ? 1 : 0,
      agree_tos_pp_a: shopperObj.shopper.agree_tos_pp_a ? 1 : 0,
    };
    console.log("sef initialStateA", initialState);

    // avoid warnings like:
    // react_devtools_backend.js:4012 Warning: `value` prop on `input` should
    // not be null. Consider using an empty string to clear the component or
    // `undefined` for uncontrolled components.
    const shopperStringCols = [
      "identifier",
      "firstname",
      "lastname",
      "shortname",
      "email",
      // "image_url",
      // "bio",
      "postal_code",
      "show_tippy_help_alert_icons",
      "show_help_video_icons",
      "agree_tos_pp_a",
      "password",
      "password_confirmation",
      // "password_digest"
    ];
      // TODO: Why is this here? "password_digest"
      // And have just added 'password' and 'password_confirmation'. Ok?

    for (const item of shopperStringCols) {
      if (initialState[item] == null) {
        initialState[item] = '';
      }
    }
    console.log("sef initialStateB", initialState);

    // Keep this in reserve for if we see more errors regarding
    // forms whose attributes are null.
    // Per https://stackoverflow.com/a/73514331 :
    // We could convert any null attr values of shopperObj to ''.
    // We get away with the below since all shopper attrs are strings.
    // const xinitialState = JSON.parse(
    //   JSON.stringify(shopperObj.shopper), (key, value) => {
    //     return value || '';
    //   }
    // );
    // console.log("sef xinitialState", xinitialState);

    const { formData, handleChange, reset, editFormValues } = useForm(initialState);

    const showTippyHelpIconLabel     = getShowTippyHelpIconLabel();

    const showTooltipsTippy          = getShowTooltipsTippy();
    const showTooltipsTippyAudio     = getShowTooltipsTippyAudio();

    const showVideoTipsTippy         = getShowVideoTipsTippy();
    const showVideoTipsTippyAudio    = getShowVideoTipsTippyAudio();

    const shopperShortnameTippyText  = getShopperShortnameTippyText();
    const shopperShortnameTippyAudio = getShopperShortnameTippyAudio();
  
    const [agreeTermsPp, setAgreeTermsPp] = useState(initialState.agree_tos_pp_a);
    const handleTermsAgree = (agreeState) => {
      setAgreeTermsPp(agreeState);
      // RadioButton monitors 'agreeTermsPp' to know whether to display as 'selected'
  
      handleChange({ target: {name: 'agree_tos_pp_a', value: agreeState} });
    };
  
    const handleAgreeClick = () => {
      handleTermsAgree(1);
    };

    // Argh, bug. The tippyAlertText icon won't show unless 'agreeTermsPp === 1'
    // Luckily, in that case, the tippyInfoText icon will show!
    // So, when it's '1' we'll use tippyAlertText to show a tippy icon (?).
    // When it's not '1', we'll use tippyInfoText (!).
    // TBD: Why won't tippyAlertText show in browser when 'agreeTermsPp !== 1' ?

    const noDeleteYourAcctLabel =
        t('global.label.no')
      + ' - '
      + t('shopper.deleteAccountButton.label');
      // No - Delete My Account

    const disagreeAndDeleteYourAcctMsg =
        t('shopper.disagreeTermsPrivacy.tippyContent')
      + ' '
      + t('shopper.deleteAccountButton.tippyContent');
      // You don't agree with the 'Terms of Service' and 'Privacy Policy'. Click this button to open a form that lets you delete your account and its data.

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();
        
            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();
        
            console.log('formData for editPrice: ', formData);
            editShopper(formData);
        
            // If there is an error we want to preserve formData and show the message.
        }

        return (
          <article>
            <HeadingBuilder
              Size        = 'h3'
              headingText = {t('shopper.formPart.headlineUpdateProfile')}
              value       = {shopper.identifier}
              // Update Shopper Profile for: uiui
            />
              <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.firstname.label')}
            // First Name:
          labelHtmlFor   = "firstname_sef"
          tippyAlertText = {t('shopper.firstname.tippyContent')}
          tippyText      = {t('shopper.firstname.tippyContent')}
        />
      </td>
      <td>
        <input
          key="10"
          type="text"
          id="firstname_sef"
          name="firstname"
          autoComplete="given-name"
          onChange={handleChange}
          value={formData.firstname}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.lastname.label')}
            // Last Name:
          labelHtmlFor   = "lastname_sef"
          tippyAlertText = {t('shopper.lastname.tippyContent')}
          tippyText      = {t('shopper.lastname.tippyContent')}
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          id="lastname_sef"
          name="lastname"
          autoComplete="family-name"
          onChange={handleChange}
          value={formData.lastname}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.shortname.label')}
            // Shortname:
          labelHtmlFor   = "shortname_sef"
          tippyAlertText = {shopperShortnameTippyText}
          tippyText      = {shopperShortnameTippyText}
          tippyAlertAudio= {shopperShortnameTippyAudio}
        />
      </td>
      <td>
        <input
          key="35"
          type="text"
          id="shortname_sef"
          name="shortname"
          autoComplete="username"
          onChange={handleChange}
          value={formData.shortname}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.email.label')}
            // Email:
          labelHtmlFor   = "email_sef"
          tippyAlertText = {t('shopper.email.tippyContent')}
          tippyText      = {t('shopper.email.tippyContent')}
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          style={{ textTransform: "lowercase" }}
          id="email_sef"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          value={formData.email}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.password.label')}
            // Password:
          labelHtmlFor   = "password_sef"
          tippyAlertText = {t('shopper.password.tippyContent')}
          tippyText      = {t('shopper.password.tippyContent')}
        />
      </td>
      <td>
        <input
          key="50"
          type="password"
          id="password_sef"
          name="password"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData.password}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.password_confirmation.label')}
            // Password Confirmation:
          labelHtmlFor   = "password_confirmation_sef"
          tippyAlertText = {t('shopper.password_confirmation.tippyContent')}
          tippyText      = {t('shopper.password_confirmation.tippyContent')}
        />
      </td>
      <td>
        <input
          key="60"
          type="password"
          id="password_confirmation_sef"
          name="password_confirmation"
          autoComplete="current-password"
          onChange={handleChange}
          value={formData.password_confirmation}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.postal_code.label')}
            // Zip/Postal Code:
          labelHtmlFor   = "postal_code_sef"
          tippyAlertText = {t('shopper.postal_code.tippyContent')}
          tippyText      = {t('shopper.postal_code.tippyContent')}
        />
      </td>
      <td>
        <input
          key="80"
          type="text"
          id="postal_code_sef"
          name="postal_code"
          autoComplete="postal-code"
          onChange={handleChange}
          value={formData.postal_code}
        />
      </td>
    </tr>
    <tr>
      <td>
        {/* Settings: */}
        <em>{t('shopper.settings.label')}</em>
      </td>
      <td>
        {null}
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {showTippyHelpIconLabel}
            // Show tooltips via [?] button
          labelHtmlFor   = "show_tippy_help_alert_icons"
          tippyAlertText = {showTooltipsTippy}
          tippyText      = {showTooltipsTippy}
          tippyAlertAudio= {showTooltipsTippyAudio}
          // ShopWeAll uses tooltips to provide ...
        />
      </td>
      <td>
        <select
          key="100"
          type="text"
          id="show_tippy_help_alert_icons"
          name="show_tippy_help_alert_icons"
          onChange={handleChange}
          value={formData.show_tippy_help_alert_icons}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('shopper.showTippyHelpIcon.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('shopper.showTippyHelpIcon.optionValue1')}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.showVideoTipsIcon.label')}
            // Show video icons
          labelHtmlFor   = "show_help_video_icons"
          tippyAlertText = {showVideoTipsTippy}
          tippyText      = {showVideoTipsTippy}
          tippyAlertAudio= {showVideoTipsTippyAudio}
          // ShopWeAll often displays a 'Show video tips' icon...
        />
      </td>
      <td>
        <select
          key="110"
          type="text"
          id="show_help_video_icons"
          name="show_help_video_icons"
          onChange={handleChange}
          value={formData.show_help_video_icons}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('shopper.showVideoTipsIcon.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('shopper.showVideoTipsIcon.optionValue1')}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('shopper.agreeTermsPrivacyA.label')}
          // I have read and agree to...
          labelHtmlFor   = "agree_tos_pp_a_sef"
          tippyAlertText = {t('shopper.agreeTermsPrivacyA.legallyBinding')}
          tippyText      = {t('shopper.agreeTermsPrivacyA.legallyBinding')}
          // These are legally binding agreements for ...
          tippyInfoText = {t('shopper.agreeTermsPrivacyA.termsPrivacy')}
          // 'Terms' is the agreement between you and ...
        />
      </td>
      <td>
        <TermsPrivacyUrls />
        <div>
          <RadioButton
            key        = "120"
            label      = {t('global.label.yes')}
            // Yes
            tippyText  = {t('shopper.agreeTermsPrivacyA.tippyContent')}
            tippyInfo  = {t('shopper.agreeTermsPrivacyA.tippyContent')}
            // Click here to show your agreement with...

            onChange   = {handleAgreeClick}
            isChecked  = {agreeTermsPp === 1}
          />
        </div>
        <HeadingBuilder
          headingText    = {noDeleteYourAcctLabel}
          // No - Delete My Account
          linkUrl        = {`/shoppers/${shopper.id}/delete`}
          tippyText      = {disagreeAndDeleteYourAcctMsg}
          tippyInfoText  = {disagreeAndDeleteYourAcctMsg}
          // You don't agree with the 'Terms of Service' and 'Privacy Policy'. Click this button...
        />
      </td>
    </tr>
  </tbody>
</Table>
          <input key="200" type='submit' value='Submit'
            disabled={
              formData.shortname.trim().length === 0
                ||
              formData.email.trim().length === 0
            }
          />
        </form>
        { errorsDoExist && <ErrorList /> }
        <BackButton />
        {/* <BackButton whatToDo={resetSkuTypeFormFlags}/> */}
      </article>
     )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default ShopperEditForm;