import React, { useState, useContext, useEffect, useRef } from 'react';
import { ShopperContext } from '../context/shopper'

import HeadingBuilder from '../components/HeadingBuilder';
import Checkbox from '../components/Checkbox';
import OrderItem from '../components/OrderItem'
import OthersOrders from '../components/OthersOrders'
import GoShoppingRow from '../components/GoShoppingRow'
import OrderBreakoutRow from '../components/OrderBreakoutRow'
import CategoryHeader from '../components/CategoryHeader'
import TripCost from './TripCost'
import GoShopping from './GoShopping'

import { tax_multiplier, get_tax_type_from_char, formatMoney, getTimestringNow } from '../utils';
import { useLookups, usePricing } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import { usePhrasing } from "../hooks/useSkuUtils";
import * as Constants from '../constants';
import { doesArrayExist, findAbsAmtAsPct } from '../utils';
    

import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

// Present a list of orderItems that is, or can be, scheduled for this trip.
// Shopper can check a box to add a non-scheduled sku to their order list.
const ShoppingList = ({trip}) => {
  console.log("slB", trip)
  const { shopper, circleShoppers, skus, prices, orderItems, signedIn,
          showOthersOrders, toggleShowOthersOrders, toggleShowOthersZeroOrders,
          showOthersZeroOrders,
          showOutlayWithTax, toggleShowOutlayWithTax,
          setShowShoppingListToFalse,
        } = useContext(ShopperContext);
  console.log("slA", circleShoppers);
  console.log("slC", orderItems);
  console.log("slD", prices);
  const { t } = useTranslation();
  const { lookup_category } = useLookups();
  const { getPrice } = usePricing();
  const { textAlignLeft } = useStyles();
  const { is_shopper_a_circle_admin } = useUtils();

  //console.log("ttr slEa", trip.tax_rate);
  //console.log("ttr slEb", trip.tax_rate/100);
  //console.log("ttr slEc", null/100);  // this is 0? Surprising.
  console.log("ttrf slF", trip.tax_rate_food/100);

  const csvLink = useRef();
  const shoppingListTableId = 'ShoppingListMainTable';

  const [downloadIsClicked, setDownloadIsClicked] = useState(false);
  const [showIdentifier, setShowIdentifier]     = useState(false);
  const [showUnitCost, setShowUnitCost]         = useState(false);
  const [showTripCost, setShowTripCost]         = useState(false);
  const [showGoShopList, setShowGoShopList]     = useState(false);
  const [showOrderBreakout, setShowOrderBreakout] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [downloadReady, setDownloadReady] = useState(false);

  const {
    getUnitCountColHeaderTippyContent,
    getOColHeaderTippyContent,
  } = usePhrasing();

  useEffect(() => {
    if (csvLink && csvLink.current && downloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  //// see eslint warning re how tripCosts, tripCostsWithTax should be in 'useMemo'
  const tripCosts = {
    '~TOTAL': 0
  };

  const tripCostsWithTax = {
    '~TOTAL': 0
  };

  const sku_Price = {};
  // variable has '_' in its name to distinguish it from the prop
  // we pass to OthersOrders component

  const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
    cShoppers: circleShoppers, circle_id: trip.circle.id, shopper_id: shopper.id
  });

  const tripSkus = trip.sku_ids.map( sku_id => skus.find(s =>  s.id === sku_id) )

  // In table data, it's overwhelming to present Tippy icon info for each row.
  // If showing tippy Alert icons is enabled then show content that blends headerTippy and rowTippy.
  // Else, we can just show the header-based Tippy content for the column header.
  const unitCountColHeaderTippyContent =
    shopper.show_tippy_help_alert_icons
      ? getUnitCountColHeaderTippyContent({ isForTextNotAudio: true })
      : t('ordersList.unit_count_col.tippyContent')

  const unitCountColHeaderAudioTippyContent =
    shopper.show_tippy_help_alert_icons
      ? getUnitCountColHeaderTippyContent({ isForTextNotAudio: false })
      : t('ordersList.unit_count_col.tippyContent')

  const oColHeaderTippyContent =
    getOColHeaderTippyContent({ isForTextNotAudio: true });

  const oColHeaderAudioTippyContent =
    getOColHeaderTippyContent({ isForTextNotAudio: false });

  const get_tax_rate = ({ taxType, trip }) => {
    return Math.round(
        10_000 * (
          tax_multiplier({ tax_type: taxType, trip }) - 1
        )  // gives something like 10_000 * (1.0650000005 - 1) = 650.000005
      )    // which rounds to 650
  
    / 100  // so that returns 650/100 = 6.5
  }

  function s_by_sku_cat_brand_label_identifier( a, b )
  {
    //console.log("sbsli", a)
    // In development, some skus have category of 'null'. Treat these as 'Other'.
    // Production now has sku edit requirements that sku.category field can't
    // be null.
    // This is a holdover from early days of the app.
    if ( (a[1].category ?? 0) < (b[1].category ?? 0) ){
        // console.log('asi 1 ootts', a[1].id, a[1].category ?? 0)
      return -1;
    }
    if ( (a[1].category ?? 0) > (b[1].category ?? 0) ){
        // console.log('asi -1 ootts', a[1].id, a[1].category ?? 0)
      return 1;
    }

    if ( a[1].brand.toLowerCase() < b[1].brand.toLowerCase() ){
      return -1;
    }
    if ( a[1].brand.toLowerCase() > b[1].brand.toLowerCase() ){
      return 1;
    }

    if ( a[1].label.toLowerCase() < b[1].label.toLowerCase() ){
      return -1;
    }
    if ( a[1].label.toLowerCase() > b[1].label.toLowerCase() ){
      return 1;
    }

    if ( a[1].identifier < b[1].identifier){
      return -1;
    }
    if ( a[1].identifier > b[1].identifier){
      return 1;
    }

    return 0;
  }

  // Schwartzian transform allows sorting objects by an attribute which itself
  // might be complex/expensive to look up.
  // In this case, since an 'order' only has a 'sku_id' and not a whole sku
  // object, we can sort the orders by associating each one with a full sku
  // object as a tuple.
  // Next sort the array of tuples by an attribute of the full sku object.
  // Now that the tuples are sorted, extracting the 'order' part of each tuple
  // (tuple[0]) provides a sorted list of orders.
  //
  // Hmm, since we go to the trouble of finding the sku detail, it may prove
  // helpful to attach such detail to the order object!
  // Well, "no", complicated to do this - changing 'order' would change state?
  function schwartzSortOrders({orders, skusOfTrip}) {
    const tuples = orders.map(
      order => {
        console.log('sl 2954 sso order', order)
        return [ order, skusOfTrip.find(s =>  s.id === order.sku_id) ]
      }
    );
    // console.log('tuples orders', tuples);

    const sortedOrders = tuples.sort(s_by_sku_cat_brand_label_identifier)
                               .map(tuple => tuple[0]);
    return sortedOrders;
  }

  const findCostForShopper = ({o, price, sku}) => {
    const effectiveUnitCost = findAbsAmtAsPct({topNum: price, sku}) / 100;
    return Number(o.abs_amount) * effectiveUnitCost;
  }

  function getOrdersOfTrip() {
    // hold the shopper's abs_amt amounts by sku (for saving into state).
    const tmpShopAmtOfSku = {};

    // Accumulate the abs_amt amounts of the OTHER circleShoppers for each sku.
    const tmpCircAmtOfSku = {};

    // For each sku, build up a semicolon-delimited string of
    // "o.shopper_id,o.abs_amount" pairs, where o.abs_amount > 0
    const tmpOthersAmtOfSku = {};

    // Filter available orderItems by whether an order_item matches this trip.
    const tripOrders = trip.trip_order_items.filter(order => {
      const o = {...order};
      console.log("oott 2954 order t.toi", o);
      const skuIdOfOrder = o.sku_id.toString();
      console.log("siooA", skuIdOfOrder);
      console.log("siooB", o.shopper_id);
      console.log("siooC", shopper.id);
      console.log("siooE", o.sku_id);

      const orderCircleShopper =
        circleShoppers.find(cs => cs.shopper_id == o.shopper_id);

      const orderShopperIdentifier = orderCircleShopper.shopper.identifier;

      const sku = tripSkus.find(s =>  s.id == o.sku_id);
      console.log('oott sku', sku);

      tmpShopAmtOfSku[skuIdOfOrder] ||= 0;
      console.log("oott init sku for shopper", tmpShopAmtOfSku);
      tmpCircAmtOfSku[skuIdOfOrder] ||= 0;
      console.log("oott init sku for non-shopper", tmpCircAmtOfSku);
      tmpOthersAmtOfSku[skuIdOfOrder] ||= '';

      if (o.shopper_id == shopper.id) {
        // discussion here: https://stackoverflow.com/questions/29280445/reactjs-setstate-with-a-dynamic-key-name
        // including this: https://stackoverflow.com/a/58733173 but: do we
        // need to use "this" here?
        // this.setState({ [`image${i}`]: image })
        // And/or: https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np
        // setTodos({...todos, [todo.id]: todo});

        // shopper will have only one order for a particular sku for a trip. So,
        // we can assign the single abs_amount we'll find for that sku to tmp-object.
        // Given that existing circle shoppers are supposed to get orderItems with
        // abs_amount of zero when another shopper adds sku to the trip list, then
        // there should be one order for the shopper for each active sku in the trip.
        // This means there will be a "header" record in the ShoppingList for each sku,
        // even if the amount is zero for the shopper, to provide context for any following
        // "Show All Shoppers" orders that show quantities ordered by other
        // CircleShoppers.
        // 231204: I think the following has been handled:
        // This breaks down for that brief period where a shopper has just been added
        // to the circle, but after some orders have been placed. This is a minor
        // issue we can handle later.
        tmpShopAmtOfSku[skuIdOfOrder] = Number(o.abs_amount);
        console.log("oott is shopper, abs_amt", Number(o.abs_amount), tmpShopAmtOfSku);

        console.log("oott sku 3453", sku);
        sku_Price[sku.id] ||= getPrice({sku_id: sku.id, trip, prices});

        console.log("oott osi", orderShopperIdentifier);
        tripCosts[orderShopperIdentifier] ||= 0;
        const costForShopper =
          findCostForShopper({o, sku, price: sku_Price[sku.id]});
        // console.log('oott sku costForShopper', sku, costForShopper)

        tripCosts[orderShopperIdentifier] += costForShopper;
        tripCosts['~TOTAL']               += costForShopper;

        const effectiveUnitCostWithTax = findAbsAmtAsPct({
          topNum: 
            sku_Price[sku.id]
            * tax_multiplier({
                tax_type: (sku.tax_type || 0), // tax_type of 0 indicates 'No Tax'
                trip,
              })
          ,sku
        }) / 100;

        const skuCostWithTaxForShopper =
          Number(o.abs_amount) * effectiveUnitCostWithTax;

        tripCostsWithTax[orderShopperIdentifier] ||= 0;
        tripCostsWithTax[orderShopperIdentifier] += skuCostWithTaxForShopper;
        tripCostsWithTax['~TOTAL']               += skuCostWithTaxForShopper;

        return true;  // tells filter to keep this orderItem
      } else {
        tmpCircAmtOfSku[skuIdOfOrder] += Number(o.abs_amount);
        console.log("oott is not shopper, abs_amt", Number(o.abs_amount), tmpCircAmtOfSku);

        if (Number(o.abs_amount) >= 0) {
          tmpOthersAmtOfSku[skuIdOfOrder] += o.shopper_id + ',' + Number(o.abs_amount) + ';';
        }

        // The main shopper should have loaded a price value for the sku
        // since everyone is supposed to have a amt-0 entry for any sku
        // that another shopper has added to the circle's shopping list.
        // But we'll check anyway...
        sku_Price[sku.id] ||= getPrice({sku_id: sku.id, trip, prices});

        const costForOtherShopper =
          findCostForShopper({o, sku, price: sku_Price[sku.id]});
        // console.log('oott sku costForOtherShopper', sku, costForOtherShopper)

        tripCosts[orderShopperIdentifier] ||= 0;
        tripCosts[orderShopperIdentifier] += costForOtherShopper;
        tripCosts['~TOTAL']               += costForOtherShopper;

        const skuCostWithTaxForShopper =
          Number(o.abs_amount)
            * sku_Price[sku.id]
            * tax_multiplier({
                tax_type: (sku.tax_type || 0), // tax_type of 0 indicates 'No Tax'
                trip,
              })
            / (sku.type === 'DiscreteItem' ? sku.unit_count : 1);

        tripCostsWithTax[orderShopperIdentifier] ||= 0;
        tripCostsWithTax[orderShopperIdentifier] += skuCostWithTaxForShopper;
        tripCostsWithTax['~TOTAL']               += skuCostWithTaxForShopper;

        return false;  // tells filter: ignore orderItem, it's not shopper's
      }

    });
    console.log('sl sku_Price', sku_Price);
    console.log('sl tripCosts', tripCosts);
    console.log('sl tripCostsWithTax', tripCostsWithTax);

    console.log("tsqos 1", tmpShopAmtOfSku);
    console.log("toqos 1", tmpOthersAmtOfSku);
    console.log("tripOrders", tripOrders);
    const ordersOfTheTrip = schwartzSortOrders({
      orders:     tripOrders,
      skusOfTrip: tripSkus
    });
    console.log("ordersOfTheTrip", ordersOfTheTrip);

    return {
      ordersOfTheTrip,
      tmpShopAmtOfSku,
      tmpCircAmtOfSku,
      tmpOthersAmtOfSku
    };
  }

  const {ordersOfTheTrip, tmpShopAmtOfSku, tmpCircAmtOfSku, tmpOthersAmtOfSku} =
    getOrdersOfTrip();

  return React.useMemo(() => {
    if (signedIn) {
      console.log('TripsOrdersQ', ordersOfTheTrip);
      console.log('TO shopAmtOfSku', tmpShopAmtOfSku);
      console.log('TO circAmtOfSku', tmpCircAmtOfSku);
      console.log('TO othersAmtOfSku', tmpOthersAmtOfSku);

      const handleIdClick = event => {
        // Clicking the 'ID' column header toggles showing of sku identifiers.
        // But, a 'downloadCsv' may be in progress, and failure to show full
        // identifiers displays an appropriate warning.
        // The shopper can disappear the warning by clicking the warning icon.
        // This sets 'downloadIsClicked' to 'false' and unsets the warning icon's
        // default setting of wanting to display, so we're back where we started.
        // The shopper could also click the 'ID' column header to expand the sku
        // identifiers. This stops showing the warning (whose default state is to
        // show) because {downloadIsClicked  &&  ! showIdentifier} is no longer
        // 'true'. (Identifiers are showing.) But the 'downloadIsClicked' value
        // of 'true' is still lurking, ready to fire up the warning if the
        // shopper decides to click 'ID', i.e. toggle back to '!showIdentifier'.
        // No. Once the warning goes away via clicking the warning icon or by
        // showing full values for sku identifiers, it should not reappear due to
        // 'downloadIsClicked'. So, let's "initialize" 'downloadIsClicked' to be
        // 'false' any time we click 'ID'. If we click 'ID' with no download in
        // progress, setting it to 'false' will only reset 'false' to 'false'.
        // If it's in progress with a warning showing, IDs must not be showing.
        // Clicking the 'downloadCsv' button does nothing since it's already
        // showing the warning about needing to show identifiers. (A no-op.)
        // But, if the shopper clicks 'ID' to show IDs, we can reset
        // 'downloadIsClicked' to 'false' in preparation for a new round of
        // 'downloadIsClicked'. 
        setDownloadIsClicked(false)

        // Now do the toggle.
        setShowIdentifier(current => !current);
      };

      const handleUcClick = event => {
        setShowUnitCost(current => !current);
      };

      const handleGoShopClick = event => {
        setShowGoShopList(current => !current);
      };

      const handleOrderBreakoutClick = event => {
        setShowOrderBreakout(current => !current);
      };

      const handleTcClick = event => {
        setShowTripCost(current => !current);
      };

      const ordersList =
        doesArrayExist(ordersOfTheTrip)
          ? ordersOfTheTrip.map(o => {
              console.log('ol oott o', o);
              console.log('ol oott o.id', o.id);
              const sku = tripSkus.find(s => s.id == o.sku_id);
              console.log('olist sku', sku);

              const thePrice = getPrice({sku_id: sku.id, trip, prices});

              const oi = <OrderItem
                        key={'oiCompo' + o.id}
                        order={o} 
                        trip={trip} 
                        sku={sku} 
                        skuAmtForShopper={ tmpShopAmtOfSku[sku.id.toString()] }
                        skuAmtForCircle ={ tmpCircAmtOfSku[sku.id.toString()] }
                        showIdentifier ={ showIdentifier }
                        showUnitCost ={ showUnitCost }
                    />

              const moreStuff =
                showOthersOrders
                  ? <OthersOrders
                      key={ 'ooCompo' + o.id }
                      if_shopper_is_circle_admin ={ if_shopper_is_circle_admin }
                      order ={ o }
                      trip={ trip } 
                      sku ={ sku }
                      skuPrice ={ thePrice }

                      // in case sku was not bumped for others(???), let's add default of '':
                      skuAmtForOthers ={ tmpOthersAmtOfSku[o.sku_id.toString()] || ''}
                    />
                  : null;
              console.log('sl ms', moreStuff);

              return [oi, moreStuff];
            })
          : null;
      console.log('ShoppingList.js ordersListQ: ', ordersList);

      const tripShoppers = trip.trip_shoppers;
      console.log('ShoppingList.js tripShoppers: ', tripShoppers);

      const tripOrderItems = trip.trip_order_items;
      console.log('ShoppingList.js tripOrderItems: ', tripOrderItems);

      const length = 26;  // per the categories named in lookup_category()
      const catSeen = Array(length).fill( 0 );
      console.log('ShoppingList.js catSeen: ', catSeen);

      const summaryColComment =
          t('ordersList.summary_col.tippyContent10')
        + ' // '
        + t('ordersList.summary_col.tippyContent20');

      const showOthersOrdersButtonText =
        showOthersOrders
          ? t('ordersList.showHideOthersOrders.labelHide')
            // Hide Others' Orders
          : t('ordersList.showHideOthersOrders.labelShow');
            // Show Others' Orders

      const goShopList =
        doesArrayExist(ordersOfTheTrip)
          ? ordersOfTheTrip.map(o => {
              console.log('ootts goShopList order', o);

              const sku = tripSkus.find(s => s.id == o.sku_id);
              console.log('ootts olist sku', sku);

              const thePrice = getPrice({sku_id: sku.id, trip, prices});

              // TODO: see note above about category might be 'null' in development
              const catSeenIndex =  (sku.category ?? 0);
              console.log('ootts ShoppingList.js catSeenA: ', sku.id, catSeenIndex, catSeen[catSeenIndex]);
              const catHeader =
                catSeen[catSeenIndex] === 0  // if this is first of category, show the header
                  ? <CategoryHeader key={o.id.toString() + sku.id.toString() + o.shopper_id.toString() + '0'}
                      categoryText = { lookup_category({category: catSeenIndex}) }
                    />
                  : null;  // we'll smush the nulls via 'flat'
              catSeen[catSeenIndex] += 1;  // mark that we've seen one of this category
              console.log('ootts ShoppingList.js catSeenB: ', sku.category, catSeen[catSeenIndex], catSeen[sku.category]);

              // if we have amt > 0 then make a GoShoppingRow for this sku
              if ( tmpShopAmtOfSku[sku.id.toString()]
                 + tmpCircAmtOfSku[sku.id.toString()]
                    > 0 ) {

                // TODO: Maybe a simpler key is possible. Is it even needed?
                // 0.81 - Kirkland, Butter, Salted, info, $14.68 ea, has 4
                const sl = <GoShoppingRow
  key = { o.id.toString() + sku.id.toString() + o.shopper_id.toString() + '_1' }
                  sku              = { sku } 
                  skuPrice         = { thePrice }
                  skuAmtForShopper = { tmpShopAmtOfSku[sku.id.toString()] }
                  skuAmtForCircle  = { tmpCircAmtOfSku[sku.id.toString()] }
                />

                // Build a string showing who ordered how much of this sku
                // ui: 4.0, am: 2.5
                const amtForEach = <OrderBreakoutRow
        key = { o.id.toString() + sku.id.toString() + trip.id.toString() + '_3' }
                  sku            = { sku } 
                  tripShoppers   = { tripShoppers } 
                  tripOrderItems = { tripOrderItems } 
                />
                console.log('ShoppingList.js amtForEach: ', amtForEach);
  
                return [catHeader, sl,  showOrderBreakout ? amtForEach : null ];
              } else {

              // Nobody wants any, so put 'null' vals - "flat" will skip past
                return [null, null, null];
              }
            })
          : null;
      console.log('ShoppingList.js goShopListQ: ', goShopList);

      const downloadCsvFromTable =
        (actionType, showIdentifier, setDownloadIsClicked) => {

        if (!showIdentifier) {
          setDownloadIsClicked(true);
        } else {
          makeCsvFromTable(actionType); // ('DOWNLOAD')}
        }
      }

      const makeCsvFromTable = (actionType) => {
        if (actionType === 'DOWNLOAD') {
          const extractData = (tableId, mapper) => {
            const myTab = document.getElementById(tableId);
            // console.log("Table guts: ", myTab);
            const rowObj = {};
            rowObj.id = {};
            rowObj.id.identifier = '';

            if (myTab) {
              const data = [...myTab.rows].map(
                (r) => [...r.cells].map(
                  (c, index) => {
    // Is the cell an input field? i.e. does it look like:
    // <td><span><input name="absAmtAsPct" type="text" value="0" inputmode="numeric" style="width: 60px;">%</span></td> 
                    const input = c.querySelector('input');
                    if (input) {
                      return input.value;
                    }

                    const textVal = c.textContent;
                    // console.log("tVal", '"'+textVal+'"');

    // TODO: Could define some regex variables to DRY out some repeated use of such patterns, e.g. for removing '%' and '$' chars.
    // TODO: Should the processing of index 0-13 be stored elsewhere? Done differently?

                    if (index === 0) {  // Identifier / Bar Code
    // If the identifier doesn't match the identifier in the previous row, reinitialize the rowObj.id object to prepare to receive new values for the columns.
                      if ( rowObj.id.identifier != textVal ) {
                        rowObj.id = {};
                        rowObj.id.identifier = textVal;
                      }

                      return rowObj.id.identifier;
                    }

    // If this 'brand' cell has text, this must be the first row for this identifier. ('brand' cells should be blank for the rows for other shoppers' orders for this sku.) This cell's text should be made available to any following rows that lack such text for this identifier, so stash it in 'rowObj.id.brand'.
    // This pattern is true for almost every column of text - this is the only notice.
                    if (index === 1) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.brand = textVal;
                      }
                      return rowObj.id.brand || textVal;  // 'textVal' in case '/\w/' failed.
                    }

                    if (index === 2) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.summary = textVal;
                      }
                      return rowObj.id.summary || textVal;
                    }

                    if (index === 3) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.store = textVal;
                      }
                      return rowObj.id.store || textVal;
                    }

                    // Shopper's shortname
                    if (index === 4) {
                      return textVal
                    }

                    if (index === 5) {
                      if ( /[\w-]/.exec(textVal) ) {
                        rowObj.id.unit_count = textVal;
                      }
                      return rowObj.id.unit_count || textVal;
                    }

    // In CSV, let's not pollute numeric vals with '$' and '%' chars:
                    if (index === 6) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.price = textVal.replace(/^[$]/, '')
                                                 .replace(/[/].*$/, '');  // rm "/lb"
                      }
                      return rowObj.id.price || textVal;
                    }

                    if (index === 7) {  // Unit Qty
                      return textVal;
                    }

                    if (index === 8) {
                      if ( /[\w.]/.exec(textVal) ) {
                        rowObj.id.unit_price = textVal;
                      }
                      return rowObj.id.unit_price || textVal;
                    }

                    if (index === 9) {  // %Wantd
                      return textVal.replace(/[%]$/, '');
                    }

    // If shopper activates "Outlay w/ Tax" (instead of plain "Outlay"), there will be extra text to indicate tax type (F, R, N). We need to remove that from Outlay values, but transforming the 'O' character into its tax rate (%) will go nicely in the 'O' column, so stash this away.
                    if (index === 10) {  // Outlay -or- Outlay w/ Tax
                      const regexpTaxType = / (.)\s$/;
                      const match = textVal.match(regexpTaxType);
                      if (match && match[1]) {
                        const taxDecimal =
                          tax_multiplier({  // convert N/R/F to 0/1/2, then get tax_rate from that
                            tax_type: get_tax_type_from_char({ char: match[1] }),
                            trip
                          }) - 1;

                        rowObj.id.X = formatMoney({ amount: taxDecimal * 100 });
                      }

                      // clean up the outlay text and return it for download.
                      return textVal.replace(/^[$]/, '')
                                    .replace(regexpTaxType, '');
                    }

                    if (index === 11) {
                      return rowObj.id.X || t('ordersList.o_col.label');
                      // e.g. rowObj.id.X || 'O';
    // Maybe in the future we'll want to show the crazy character?
    // Or a different localizable character?
    // For now it's good to show the effective tax rate here for "w/ tax".
                    }

                    if (index === 12) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.circleAmt = textVal.replace(/[%]$/, '');
                      }
                      return rowObj.id.circleAmt || textVal;
                    }

                    if (index === 13) {
                      if ( /\w/.exec(textVal) ) {
                        rowObj.id.circleOutlay = textVal.replace(/^[$]/, '');
                      }
                      return rowObj.id.circleOutlay || textVal;
                    }

                    return textVal;
                  }
                )
              );

      const csvHeaders = [
          t('ordersList.id_col.label'),                   // "ID",
          t('ordersList.brand_col.label'),                // "Brand",
          t('ordersList.summary_col.label'),              // "Summary",
          t('ordersList.store_col.label'),                // "Store",
          t('ordersList.info_col.csvLabel'),              // "Shopper",
          t('ordersList.unit_count_col.label'),           // "Unit Count",
     [ `${t('ordersList.price_col.label')} ($)` ],        // "Price ($)",
          t('ordersList.unit_qty_col.label'),             // "Unit Qty",
          t('ordersList.unit_cost_col.label'),            // "Unit Cost",
          t('ordersList.abs_amt_col.label'),              // "%Wantd",

 showOutlayWithTax
   ? [ `${t('ordersList.outlay_col.label_w_tax')} ($)` ]  // "Outlay w/Tax ($)"
   : [ `${t('ordersList.outlay_col.label')} ($)` ],       // "Outlay ($)",

 showOutlayWithTax
   ? [ `${t('ordersList.o_col.csvLabel')} (%)` ]          // "Tax (%)" 
   :      t('ordersList.o_col.csvLabel'),                 //  "O",

     [ `${t('ordersList.circle_amt_col.label')} (%)` ],   // "Circle Amt (%)",
          t('ordersList.circle_price_col.label'),         // "Circle $$",

      ];

              data[0] = csvHeaders;
              console.log("data0: ", data);

              //return data.map(mapper);
              return data;
            }
          };
        
          const newDataForDownload = extractData(shoppingListTableId, (x) => ({
            // e.g.
            // name: x[0],
            // address: x[1],
            // city: x[2],
            // state: x[3],
          }));
          //console.log("dataB: ", newDataForDownload);

          setDataForDownload(newDataForDownload);
          setDownloadReady(true);
        }
      }

        const regex = /[ ']/g;
        const circleName = trip.circle.identifier.replace(regex, '_');

        return (
          <article>
              <h3 id="YourShoppingListH">
                {/* Trip's Shopping List */}
                {t('ordersList.formPart.headlineLabel')}
              </h3>
              <HeadingBuilder
                Size           = 'h5'
                headingText    = {t('ordersList.button_text.addItemToShoppingList')}
                // Put a Sku on the Shopping List
                buttonAction   = {setShowShoppingListToFalse}
                tippyAlertText = {t('ordersList.button_text.tippyContentAddItem')}
                tippyText      = {t('ordersList.button_text.tippyContentAddItem')}
                // Click HERE to put a Sku from your Maybe-List onto the Shopping List...
              />
              <br/>
              { ordersList && (
              showTripCost
                ? <TripCost
                    trip = {trip}
                    handleTcClick = {handleTcClick}
                    tripCosts = {tripCosts}
                    tripCostsWithTax = {tripCostsWithTax}
                  />
                : <button onClick={handleTcClick}>
                    {t('ordersList.button_text.showTripCost')}
                    {/* Show Trip Cost */}
                  </button>
              )}
              <br/>
              { ordersList && (
              showGoShopList
                ? <>
                  <GoShopping
                    trip = {trip}
                    handleGoShopClick = {handleGoShopClick}
                    goShopList = {goShopList}
                    showOrderBreakout = {showOrderBreakout}
                    handleOrderBreakoutClick = {handleOrderBreakoutClick}
                  />
                  <br/>
                  </>
                : <HeadingBuilder
                    headingText    = {t('trip.goShopping.showGoShopList')}
                    // Show 'Go Shopping' List
                    buttonAction   = {handleGoShopClick}
                    tippyAlertText = {t('trip.goShopping.showListButtonTippyContent')}
                    tippyText      = {t('trip.goShopping.showListButtonTippyContent')}
                    // Click this button to view a list of what everyone wants....
                  />
              )}

              { ordersList && <>
              <br/>
              <HeadingBuilder
                headingText    = {showOthersOrdersButtonText}
                // [Show/Hide] Others' Orders
                buttonAction   = {toggleShowOthersOrders}
                tippyAlertText = {t('ordersList.showHideOthersOrders.tippyContent')}
                tippyText      = {t('ordersList.showHideOthersOrders.tippyContent')}
                // Click to toggle showing everyone's Orders for each Sku of the Trip....
              />
              </>
              }

              { ordersList && if_shopper_is_circle_admin && showOthersOrders
                  && <>
                    <Checkbox
                      isChecked    = {showOthersZeroOrders}
                      setIsChecked = {toggleShowOthersZeroOrders}
                      label        =
                          {t('ordersList.showHideOthersZeroOrders.labelShow')}
                      // Show Others' Zero Orders

                      tippyAlert   =
                        {t('ordersList.showHideOthersZeroOrders.tippyContent')}
                      // Click to toggle showing the complete list of Orders for each Sku of the Trip....
                    />
                  </>
              }

              { ordersList && showOutlayWithTax && <>
                <br/>
                {t('ordersList.pageText.regSalesTax')}
                  {/* RegSalesTax: */}
                  {<>{get_tax_rate({ taxType: 1, trip })}%, </>}
                {t('ordersList.pageText.foodSalesTax')}
                  {/* FoodSalesTax:  */}
                  {<>{get_tax_rate({ taxType: 2, trip })}% </>}
              </>}

              { ordersList
                  ?<>
              <br/>
              <HeadingBuilder
                buttonAction =  {(e) => downloadCsvFromTable('DOWNLOAD',
                                                        showIdentifier,
                                                        setDownloadIsClicked)}
                headingText = {t('ordersList.button_text.downloadReport')}
                // Download details to File

                tippyAlertText = {t('ordersList.button_text.tippyContentDownloadReport')}
                tippyText      = {t('ordersList.button_text.tippyContentDownloadReport')}
                tippyInfoText  = {t('ordersList.button_text.infoDebugText')}

                forceShowBWarning = {downloadIsClicked  &&  ! showIdentifier}
                setShowBWarning   = {setDownloadIsClicked}
                bWarningMsg       = {t('ordersList.button_text.expandIdColDownloadWarning')}
                // Click column header "ID" to include Sku ID values...
              />
              <CSVLink
                data={dataForDownload}
                filename={'swa'
                  + '_' + circleName + trip.circle.postal_code
                  + '_trip'
                  + '_' + trip.day
                  + '-' + trip.hour
                  + '__' + getTimestringNow().substring(2)
                  + '.csv'}
                  // swa_bloo99999_trip_2022-09-06-1459__230228172423.csv
                className='hidden'
                ref={csvLink}
                target='_blank'
              />
              <br/>

                    <Table id={shoppingListTableId}
                           style={textAlignLeft}
                           striped bordered hover
                           size="sm" >
                    <thead>
                      <tr>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.id_col.label')}
                            // ID
                            buttonAction   = {handleIdClick}
                            tippyAlertText = {t('ordersList.id_col.tippyContent')}
                            tippyText      = {t('ordersList.id_col.tippyContent')}
                            // Click to toggle showing each item's identifier....
                          />
                        </th>
                        <th>{t('ordersList.brand_col.label')}</th>
                            {/* Brand */}
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom-left'
                            headingText    = {t('ordersList.summary_col.label')}
                            // Summary
                            tippyAlertText = {summaryColComment}
                            tippyText      = {summaryColComment}
                            // Click a label to see details about the item and even change it. // The tooltip for the item shows its category.
                          />
                        </th>
                        <th>{t('ordersList.store_col.label')}</th>
                            {/* Store */}
                        <th>{t('ordersList.who_col.label')}</th>
                            {/* Who */}
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.unit_count_col.label')}
                            // Unit Count
                            tippyAlertText = {unitCountColHeaderTippyContent}
                            tippyAlertAudio= {unitCountColHeaderAudioTippyContent}
                            tippyText      = {unitCountColHeaderTippyContent}
                            // Shows how many individual units come with each purchase.
                            //
                            // [and if we are showing Tippy Alert icons, continue with:]
                            // Click the blue button to raise your order by one full sku.
                            // Then click the pink 'O' sign to add your order to your Circle's orders."
                          />
                        </th>
                        <th>{t('ordersList.price_col.label')}</th>
                            {/* Price */}
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.unit_qty_col.label')}
                            // Unit Qty
                            tippyAlertText = {t('ordersList.unit_qty_col.tippyContent')}
                            tippyText      = {t('ordersList.unit_qty_col.tippyContent')}
                            // How many subunits do you want?
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.unit_cost_col.label')}
                            // Unit Cost
                            buttonAction   = {handleUcClick}
                            tippyAlertText = {t('ordersList.unit_cost_col.tippyContent')}
                            tippyText      = {t('ordersList.unit_cost_col.tippyContent')}
                            // Click to toggle showing Unit Cost for the item.
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.abs_amt_col.label')}
                            // Abs Amt
                            tippyAlertText = {t('ordersList.abs_amt_col.tippyContent')}
                            tippyText      = {t('ordersList.abs_amt_col.tippyContent')}
                            // What percentage of the item do you want?
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {
                                               showOutlayWithTax
                                                 ? t('ordersList.outlay_col.label_w_tax')
                                                 : t('ordersList.outlay_col.label')
                                             }
                            // Outlay
                            buttonAction   = {toggleShowOutlayWithTax}
                            tippyAlertText = {t('ordersList.outlay_col.tippyContent')}
                            tippyText      = {t('ordersList.outlay_col.tippyContent')}
                            // Your portion of the cost for the item
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.o_col.label')}
                            // Having a wacky character for a header doesn't look right, so we'll use "O", not O_COL_SYM.

                            tippyAlertText = {oColHeaderTippyContent}
                            tippyAlertAudio= {oColHeaderAudioTippyContent}
                            tippyText      = {oColHeaderTippyContent}
                            // When it turns pink, clicking the "O" sign below is a way to update the database with the amount you want of the product
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.circle_amt_col.label')}
                            // Circle Amt
                            tippyAlertText = {t('ordersList.circle_amt_col.tippyContent')}
                            tippyText      = {t('ordersList.circle_amt_col.tippyContent')}
                            // Shows the combined requested amount from everyone in your Circle for getting an item.
                          />
                        </th>
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom'
                            headingText    = {t('ordersList.circle_price_col.label')}
                            // Circle $$
                            tippyAlertText = {t('ordersList.circle_price_col.tippyContent')}
                            tippyText      = {t('ordersList.circle_price_col.tippyContent')}
                            // Shows how much your Circle will spend on an item
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersList.flat(1)}
                    </tbody>
                    </Table>
                    {
                      ordersList.length > Constants.SMALL_NUM_OF_OIS  // 5
                        ? <HeadingBuilder
                            Size           = 'h5'
                            headingText    = {t('ordersList.button_text.addItemToShoppingList')}
                            // Put a Sku on the Shopping List
                            buttonAction   = {setShowShoppingListToFalse}
                            goButtonText   = {Constants.GET_ICON_RT_ARROW}
                            tippyAlertText = {t('ordersList.button_text.tippyContentAddItem')}
                            tippyText      = {t('ordersList.button_text.tippyContentAddItem')}
                            // Click HERE to put a Sku from your Maybe-List onto the Shopping List...
                          />
                        : null
                    }
                   </>
                  : <p>{t('ordersList.pageText.noOrderItemsDefined')}</p>
                       // No Skus chosen for this Trip
              }
          </article>
        )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
  },
  [ ordersOfTheTrip, tmpShopAmtOfSku, tmpCircAmtOfSku, tmpOthersAmtOfSku,
    showOrderBreakout,
    if_shopper_is_circle_admin, showTripCost, trip, tripCosts, tripCostsWithTax,
    toggleShowOthersOrders, showOthersOrders, toggleShowOthersZeroOrders,
    showOthersZeroOrders,
    setShowShoppingListToFalse,
    showOutlayWithTax, toggleShowOutlayWithTax,
    lookup_category, textAlignLeft, dataForDownload,
    oColHeaderTippyContent,
    unitCountColHeaderTippyContent,
    oColHeaderAudioTippyContent,
    unitCountColHeaderAudioTippyContent,
    tripSkus,
    showIdentifier,
    downloadIsClicked, 
    // skus, 
    showGoShopList, showUnitCost, signedIn, prices, getPrice, t
  ]
  // TODO: 221206 does 't' really have to be in the dependency array?
  // https://bobbyhadz.com/blog/react-hook-useeffect-has-missing-dependency
  );
}

export default ShoppingList;
