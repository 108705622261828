import React, { useState, useContext, useEffect } from 'react';
import { ShopperContext } from '../context/shopper'
import { useStyles } from "../hooks/useStyles";
import * as Constants from '../constants';
import SkuMenuLink from '../components/SkuMenuLink'
import { ErrorList } from '../components/ErrorList';
import SkuMenuFilters from '../components/SkuMenuFilters'
import HeadingBuilder from '../components/HeadingBuilder'
import { useSkuSorts } from "../hooks/useSkuUtils";
import { useUtils } from "../hooks/useUtils";
import { doesArrayExist } from '../utils';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';

// Present a menu (Maybe-List) of skus eligible for this trip.
// Shopper can check a box to add sku to their order list.
const SkuMenu = ({trip}) => {
  console.log("splB", trip)
  const { shopper, skus, orderItems, signedIn,
          setInSkuMenuToTrue, setInSkuMenuToFalse,
          setShowShoppingListToTrue,
          errorsDoExist,
        } = useContext(ShopperContext);
  const { tableShow, textAlignLeft } = useStyles();

  // set the context state variable 'inSkuMenu' to be true when
  // 'SkuMenu' component mounts and 'false' when it unmounts.
  useEffect(() => {
    setInSkuMenuToTrue();

    return () => {
      setInSkuMenuToFalse();
    };
  }, [setInSkuMenuToTrue, setInSkuMenuToFalse]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand]       = useState('');
  const [selectedSummary, setSelectedSummary]   = useState('');
  const [showMenuFilters, setShowMenuFilters]   = useState(false);

  const [countOfPlus, setCountOfPlus] = useState(0);
  const incrementPlus = () => {setCountOfPlus(() => countOfPlus + 1)}
  const decrementPlus = () => {setCountOfPlus(() => countOfPlus - 1)}

  const { s_by_sku_cat_brand_label } = useSkuSorts();
  const { shoppers_order_for_this_sku_this_trip } = useUtils();
  const { t } = useTranslation();

  if (signedIn) {
      console.log("splC", skus)
      console.log("splD", orderItems)

      function getSkusOfCircle() {
        // Wow: it took lengthy examination to learn that the function
        // would only (?) return the new mapped array if it had been
        // saved into a variable (e.g. into "skusOfTheCircle"), and
        // then return the variable. Without that, expecting the
        // function to return the implicit return of filter/map's
        // array did not work. The function would only return
        // "undefined". :-(
        const skusOfTheCircle =
          skus.filter(sku => sku.circle_id === trip.circle.id)
              .map(sku => {
            let s = { ...sku };
            console.log("absAmtOfSku0", s);
            // console.log('sotc: find this shopper sku trip', shopper.id, s.id, trip.id)
            const matchingOrder = shoppers_order_for_this_sku_this_trip({
              oItems: orderItems,
              shopper_id: shopper.id,
              sku_id: s.id,
              trip_id: trip.id
            })
            console.log("sotc: matchingOrderA", matchingOrder);
    
            const absAmtOfSku =
              (matchingOrder === undefined)  // || matchingOrder.length == 0
                ? null  // "_"
                : matchingOrder.abs_amount;
            console.log("absAmtOfSkuA", absAmtOfSku);
    
            const aqos = { 'absAmtOfSku': absAmtOfSku };
    
            console.log("absAmtOfSkuB", aqos);
            console.log("absAmtOfSkuC", s);

            const skuWithAbsAmt = { ...s, ...aqos };
            console.log("absAmtOfSkuD", skuWithAbsAmt);
            return skuWithAbsAmt;
          })

        console.log("skusOfTheCircle", skusOfTheCircle);
        return skusOfTheCircle;
      }

      const skusOfCircle = getSkusOfCircle();
      console.log('Skus of Circle', skusOfCircle);

      function handleCategoryChange(e) { setSelectedCategory(e.target.value) }
      function handleBrandChange(e)    { setSelectedBrand(e.target.value) }
      function handleSummaryChange(e)  { setSelectedSummary(e.target.value) }

      function initializeFilters() {
        setSelectedCategory('');
        setSelectedBrand('');
        setSelectedSummary('');
        setShowMenuFilters(current => false);
      }

      const skusList =
        doesArrayExist(skusOfCircle)
        ? skusOfCircle
            // keep sku if filter is unset (''), else keep if matches shopper's input
            .filter(sku => selectedCategory === '' || sku.category === Number(selectedCategory) ) 
            .filter(sku => selectedBrand === ''    || sku.brand.toLowerCase().includes(selectedBrand.toLowerCase()) )
            .filter(sku => selectedSummary === ''  || sku.label.toLowerCase().includes(selectedSummary.toLowerCase()) )
            // keep any sku that's not hidden or that has an active order (even if 0).
            .filter(sku => !sku.is_hidden  ||  shoppers_order_for_this_sku_this_trip({
                oItems: orderItems,
                shopper_id: shopper.id,
                sku_id: sku.id,
                trip_id: trip.id
              })
            )
            .sort( s_by_sku_cat_brand_label )
            .map(sku => {
              // these elements are already copies, so don't need to recopy
              console.log('sku de circle', sku);
  
              return <SkuMenuLink key={sku.id} sku={sku} trip={trip}
                incrementPlus={incrementPlus}
                decrementPlus={decrementPlus}
              />
            })
        : null;
      console.log('SkuMenu.js skusListQ: ', skusList);
      console.log('tl is t/f', skusList ? 'there are skus' : 'no skus');

      const handleShowMenuFiltersClick = event => {
        setShowMenuFilters(current => true);
      };

      return (
          <article>
              <HeadingBuilder
                Size        = 'h1'
                headingText = {t('sku.skuMenuAddSku.title')}
                // Sku Maybe-List
              />
              <HeadingBuilder
                Size        = 'strong'
                headingText = {t('trip.circlePrompt.label')}
                value       = {trip.circle.identifier}
                // Circle: uipuy
              />
              <br/>

              { skusList
                ? countOfPlus > 0
                  ? <HeadingBuilder
                      Size           = 'h5'
                      headingText    = {t('sku.skuMenuAddSku.headline')}
                      // Click the "+" sign to add a Sku to this Trip's
                      // shopping list.
                      tippyAlertText =
                                {t('sku.skuMenuAddSku.headlineTippyContent')}
                      tippyText      =
                                {t('sku.skuMenuAddSku.headlineTippyContent')}
                    />
                  : <HeadingBuilder
                      Size           = 'h5'
                      headingText    = {t('sku.skuMenuAddSku.allSkusPicked')}
                      // Nothing to do here - There are no Skus left to pick
                      // for this Trip!
                      tippyAlertText =
                            {t('sku.skuMenuAddSku.allSkusPickedTippyContent')}
                      tippyText      =
                            {t('sku.skuMenuAddSku.allSkusPickedTippyContent')}
                    />
                : null
              }

              <HeadingBuilder
                tippyAlertText = {t('sku.circleAddSku.directTippyContent')}
                tippyText      = {t('sku.circleAddSku.directTippyContent')}
                tippyInfoText  = {t('sku.skuMenuAddSku.tippyContentB')}
                linkUrl        = {`/circles/${trip.circle.id}/skus`}
                goButtonText   = {Constants.GET_ICON_CREATE}
                linkUrl2       = {`/circles/${trip.circle.id}/search_skus`}
                goButton2Text  = {Constants.GET_ICON_SEARCH}
                headingText    = {t('sku.circleAddSku.label')}
                // Add a new Sku to Circle's Maybe-List

              />
              <br/>
              {/* If no skusList then don't show anything about filters */}
              { (showMenuFilters && skusList)
                ? <SkuMenuFilters
                    handleCategoryChange = {handleCategoryChange}
                    handleBrandChange    = {handleBrandChange}
                    handleSummaryChange  = {handleSummaryChange}
                    initializeFilters = {initializeFilters}
                  />
                : skusList
                  ? <HeadingBuilder
                    buttonAction   = {handleShowMenuFiltersClick}
                    headingText    = {t('sku.skuMenuAddSku.showFilters')}
                    // Show Filters
              tippyAlertText = {t('sku.skuMenuAddSku.showFiltersTippyContent')}
              tippyText      = {t('sku.skuMenuAddSku.showFiltersTippyContent')}
                    />
                  : null
              }
              <br/>
              <HeadingBuilder
                buttonAction   = {setShowShoppingListToTrue}
                goButtonText   = {Constants.GET_ICON_RT_ARROW}
        headingText    = {t('ordersList.button_text.returnToShoppingList')}
                // Return to Trip's Shopping List
        tippyAlertText = {t('ordersList.button_text.returnToListTippyContent')}
        tippyText      = {t('ordersList.button_text.returnToListTippyContent')}
              />

              { errorsDoExist && <ErrorList /> }

              { skusList
                  ? <>
                    <Table striped bordered hover style={tableShow}>
                    <thead style={textAlignLeft}>
                      <tr>
                        <th>{t('ordersList.abs_amt_col.label')}</th>{/* Abs Amt */}
                        <th>{t('ordersList.brand_col.label')}</th>{/* Brand */}
                        <th>
                          <HeadingBuilder
                            containerType  = 'container-hug-bottom-left'
                            headingText    = {t('ordersList.summary_col.label')}
                            // Summary
                            tippyAlertText = {t('ordersList.summary_col.tippyContent10')}
                            tippyText      = {t('ordersList.summary_col.tippyContent10')}
                            // Click a label to see details about the item and even change it.
                          />
                        </th>
                        <th>{t('ordersList.store_col.label')}</th>{/* Store */}
                      </tr>
                    </thead>
                    <tbody style={textAlignLeft}>
                      {skusList}
                    </tbody>
                    </Table>
                    {
                      skusList.length > Constants.SMALL_NUM_OF_MENU_ITEMS  // 6
                        ? <HeadingBuilder
                            Size           = 'h5'
                            buttonAction   = {setShowShoppingListToTrue}
                            goButtonText   = {Constants.GET_ICON_RT_ARROW}
        headingText    = {t('ordersList.button_text.returnToShoppingList')}
                            // Return to Trip's Shopping List
        tippyAlertText = {t('ordersList.button_text.returnToListTippyContent')}
        tippyText      = {t('ordersList.button_text.returnToListTippyContent')}
                          />
                        : null
                    }
                    </>
                  : <p>
                      {t('sku.skuMenuAddSku.noSkusYet')}
                      {/* No Skus defined for this Circle */}
                    </p>
              }
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default SkuMenu;
