// Don't apply 'HeadingBuilder' for this component's 'Tippy contents' (label fields with id, extra classes)
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import { RadioButton } from "./RadioButton";
import { ErrorList } from './ErrorList';
import HeadingBuilder from './HeadingBuilder';
import { TermsPrivacyUrls } from './pieces/TermsPrivacyUrls';
import { useTranslation } from "react-i18next";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Signup = () => {
  const { signup, spAuthIsActive } = useContext(ShopperContext);
  const { errorsDoExist, dismissErrorsList, addToErrorsList } = useContext(ShopperContext);
  const { setErrorsDoExistToFalse, setErrorsDoExistToTrue } = useContext(ShopperContext);
  const { visibilityCollapse } = useStyles();
  const { randomChar, stringGen } = useUtils();
  const [agreeTermsPp, setAgreeTermsPp] = useState(0);
  const [showConfirmYourEmailMsg, setShowConfirmYourEmailMsg] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  // TODO: maybe remove some of these, save them for entry in Profile update
  const initialState = {
    identifier: '',
    firstname: '',
    lastname: '',
    shortname: '',
    email: '',
    // image_url: '',
    // bio: '',
    postal_code: '',
    agree_tos_pp_a: 0,
    password: '',
    password_confirmation: '',
    show_help_video_icons: true,
    show_tippy_help_alert_icons: true,
  };
  const { formData, handleChange, reset, editFormValues } = useForm(initialState);

  const dismissConfirmEmailButton = () => {
    setShowConfirmYourEmailMsg(false);
  };

  const handleTermsAgree = (agreeState) => {
    setAgreeTermsPp(agreeState);
    // RadioButtons monitor 'agreeTermsPp' to know whether to display as 'selected'

    handleChange({ target: {name: 'agree_tos_pp_a', value: agreeState} });
  };

  const handleAgreeClick = () => {
    handleTermsAgree(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('signup formdata', formData)
    const configObj = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData })
    }

    dismissErrorsList();
    setErrorsDoExistToFalse();

    fetch('/api/signup', configObj)
    .then(res => res.json())
    .then(shopper => {
       if (!shopper.errors) {
          if (spAuthIsActive) {
            setShowConfirmYourEmailMsg(true);
          } else {
            signup(shopper)
            navigate('/')
          }
       } else {
           //reset()
           //const errorLis = shopper.errors.map(err => <li>{err}</li>)
           //const errorLis = shopper.errors.map(err => <li className="stdError" key={err} >{err}</li>)
           //setErrorsList(errorLis)

           console.log("api_signup error(s)", shopper);
           const errorLis = shopper.errors.map(err => <li className="stdError" key={err} >{err}</li>)
           // TODO: is "key={err}" reliably different? (is this sufficient?)

           //addToErrorsList(_errorsList => [ ..._errorsList, errorLis ])
           addToErrorsList( errorLis )
           setErrorsDoExistToTrue();
       }
    })
  }

  return (
    <div>
      <div className="container-fluid mt-5 ">
        <div className='signInBkgrnd' id="duotone_sign_up"></div>
        <div className="container mt-6 p-5">
          <h1 className="fs-1 white fw-light">SIGN UP</h1>
          <p className='fs-3'>{ t('signup.tagline.line1') }<br />
                              { t('signup.tagline.line2') }
           {/* And tell EVERYBODY\n to join you in the savings! */}
          </p>
            { t('signup.tagline.line11') }<br />
            { t('signup.tagline.line12') }<br />
     <small>{ t('signup.tagline.line13') }</small>
            {/* Note: Alpha testing in progress.<br />
            Call/text B.Johnson, 561-332-4798, for a Sign Up code.
            (Input fields with * are required.) */}
        </div>
       { (showConfirmYourEmailMsg && spAuthIsActive)
          ? <>
              <HeadingBuilder
                containerType  = 'h4'
                Size           = 'strong'
                headingText    = { t('signup.success.confirmYourEmailMsg') }
                // Success! Now please check your email for confirmation instructions.
                tippyAlertText = { t('signup.success.tippyContent') }
                tippyText      = { t('signup.success.tippyContent') }
                // We have emailed you with a link to verify your email address.
              />
              <HeadingBuilder
                headingText    = { t('signup.dismissConfirmButton.label') }
                // Click to Dismiss
                buttonAction   = {dismissConfirmEmailButton}
                tippyAlertText = { t('signup.dismissConfirmButton.tippyContent') }
                tippyText      = { t('signup.dismissConfirmButton.tippyContent') }
                // Click to dismiss this message and show the 'Sign Up' form again.
              />
              <br/>
            </>
          : 

        // show the Sign Up form
        <div className="container">
          <div className="row row-cols-1 d-flex justify-content-center">
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <Tippy content= { t('shopper.agreeTermsPrivacyA.termsPrivacy') } >
                {/* 'Terms' is the agreement between you and Festa ... */}
                  <label htmlFor="colFormLabelSm agree_tos_pp_a" className="col-sm-4 col-form-label text-end fw-bold"
                  >
                    <TermsPrivacyUrls />
                  </label>
                </Tippy>
                &nbsp;
                <div className="col-sm-6">
                  <RadioButton
                    key="110"
                    label={t('shopper.agreeTermsPrivacyA.label')}
                    // I have read and agree to...
                    tippyAlert={t('shopper.agreeTermsPrivacyA.tippyContent')}
                    // Click here to show your agreement with...
                    tippyInfo={t('shopper.agreeTermsPrivacyA.tippyUndoClick')}
                    // You can undo your click by refreshing your browser tab.
                    onChange={handleAgreeClick}
                    isChecked={agreeTermsPp === 1}
                  />
                </div>*
              </div>

              <div className="row mb-3">
                <Tippy content= {
                  t('shopper.firstname.tippyContent') + ' // ' + t('shopper.nameContext')
                } >
                  <label htmlFor="colFormLabelSm firstname" className="col-sm-4 col-form-label text-end fw-bold"
                    id="colFormLabelSm">
                      {/* First Name: */}
                      { t('shopper.firstname.label') }
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="10"
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="John"
                    autoComplete="given-name"
                    onChange={handleChange}
                    value={formData.firstname}
                  />
                </div>
              </div>

              <div className="row mb-3">
                {/* <Tippy content= { t('shopper.lastname.tippyContent') } > */}
                <Tippy content= {
                  t('shopper.lastname.tippyContent') + ' // ' + t('shopper.nameContext')
                } >
                  <label htmlFor="colFormLabelSm lastname" className="col-sm-4 col-form-label text-end fw-bold"
                    >
                    { t('shopper.lastname.label') }
                    {/* Last Name: */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="20"
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Doe"
                    autoComplete="family-name"
                    onChange={handleChange}
                    value={formData.lastname}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <Tippy content= { t('shopper.identifier.tippyContent') } >
                  <label htmlFor="colFormLabelSm identifier" className="col-sm-4 col-form-label text-end fw-bold"
                    >
                    { t('global.label.username') }
                    {/* Username */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="30"
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    id="identifier"
                    name="identifier"
                    autoComplete="username"
                    onChange={handleChange}
                    value={formData.identifier}
                  />
                </div>*
              </div>

              {/* Shortname: */}
              {/* <div className="row mb-3">
                <Tippy content= { t('shopper.shortname.tippyContent') } >
                  <label htmlFor="colFormLabelSm shortname" className="col-sm-4 col-form-label text-end fw-bold"
                    >
                    { t('shopper.shortname.label') }
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="35"
                    type="text"
                    id="shortname"
                    name="shortname"
                    autoComplete="username"
                    onChange={handleChange}
                    value={formData.shortname}
                  />
                </div>
              </div> */}

              <div className="row mb-3">
                <Tippy content= { t('shopper.email.tippyContent') } >
                  <label htmlFor="colFormLabelSm email" className="col-sm-4 col-form-label text-end fw-bold"
                  >
                    { t('shopper.email.label') }
                    {/* Email */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="40"
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    id="email"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </div>*
              </div>

              <div className="row mb-3">
                <Tippy content= { t('shopper.password.tippyContent') } >
                  <label htmlFor="colFormLabelSm password" className="col-sm-4 col-form-label text-end fw-bold"
                  >
                    { t('shopper.password.label') }
                    {/* Password */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="50"
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    value={formData.password}
                  />
                </div>*
              </div>

              <div className="row mb-3">
                <Tippy content= { t('shopper.password_confirmation.tippyContent') } >
                  <label htmlFor="colFormLabelSm password_confirmation" className="col-sm-4 col-form-label text-end fw-bold"
                  >
                    { t('shopper.password_confirmation.label') }
                    {/* Password Confirmation */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="60"
                    type="password"
                    id="password_confirmation"
                    name="password_confirmation"
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={formData.password_confirmation}
                  />
                </div>*
              </div>

              <div className="row mb-3">
                <Tippy content= { t('shopper.postal_code.tippyContent') } >
                  <label htmlFor="colFormLabelSm postal_code" className="col-sm-4 col-form-label text-end fw-bold"
                  >
                    { t('shopper.postal_code.label') }
                    {/* Zip/Postal Code */}
                  </label>
                </Tippy>&nbsp;
                <div className="col-sm-6">
                  <input
                    className="form-control form-control-sm"
                    key="80"
                    type="text"
                    id="postal_code"
                    name="postal_code"
                    autoComplete="postal-code"
                    onChange={handleChange}
                    value={formData.postal_code}
                  />
                </div>
              </div>

              <div className="container">
                <div className="row row-cols-1 d-flex justify-content-center">
                  { errorsDoExist && <ErrorList /> }
                </div>
              </div>
              
              <input
                key="100"
                className="btn btn-primary"
                type='submit'
                value='Submit'
                disabled={
                  formData.identifier.trim().length === 0
                    ||
                  formData.email.trim().length === 0
                    ||
                  formData.password.trim().length === 0
                    ||
                  formData.password_confirmation.trim().length === 0
                }
              />
            </form>
          </div>
        </div>
       }
      </div>
    </div>
  )
}

export default Signup;
